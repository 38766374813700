/**
 * Reducers de alertas
 * @module ReducersAlertas
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  showalert: false,
  prefix: "fas",
  typeAlert: "Error",
  message: "Error",
}
export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    /**
     * Cambio del valor de show alert
     * @function
     * @name addEvent
     * @param {Object} action - Objecto que contiene el payload de los nuevos valores
     * @property {Boolean} action.payload.show - Valor para showalert
     * @return {Object} Devuelve el state con el nuevo valor del payload
     */
    addEvent: (state, action) => ({
      ...state,
      showalert: action.payload.show,
    }),
    /**
     * Cambio del valor del message del alert
     * @function
     * @name addMessage
     * @param {Object} action - Objecto que contiene el payload de los nuevos valores
     * @property {String} action.payload.type - Tipo de alerta
     * @property {String} action.payload.message - Mensaje de la alerta
     * @return {Object} Devuelve el state con nuevos valores del payload
     */
    addMessage: (state, action) => ({
      ...state,
      typeAlert: action.payload.type,
      message: action.payload.message,
    }),
  },
})

export const { addEvent, addMessage } = alertsSlice.actions
export default alertsSlice.reducer
