/**
 * Componente para la implementacion de alertas
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module Alertas
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from "react-redux"

/**
 * Renderiza el alerta dependiendo la respuesta
 * @function
 * @name Alertas
 */
function Alertas() {
  const estadoAlerta = useSelector((state) => state.alert)
  let alertaIcono = "times"
  let alertaColor = "#e30939"

  /**
   * Evalua el tipo de alerta que se implementara en cada caso de uso
   * @param {String} typeAlert - Nombre del alerta
   */
  switch (estadoAlerta.typeAlert) {
    case "Error": {
      alertaIcono = "times"
      alertaColor = "#e30939"
      break
    }
    case "Success": {
      alertaIcono = "check"
      alertaColor = "#08D081"
      break
    }
    case "Warning": {
      alertaIcono = "exclamation"
      alertaColor = "#F5C41D"
      break
    }
    default: {
      alertaIcono = "times"
      alertaColor = "#e30939"
      break
    }
  }
  return (
    <div
      className={`complete_screen content_center_screen container_alert ${
        estadoAlerta.showalert ? "show_container_alert" : ""
      }`}
    >
      <div className="content_alert">
        <div className="container_shape_alert">
          <div className="shape_alert">
            <span
              className={`shape_alert_bg ${
                estadoAlerta.showalert ? "animate_shape_alert_bg" : ""
              }`}
              style={{ background: alertaColor }}
            />
            <FontAwesomeIcon icon={[estadoAlerta.alerta_prefix, alertaIcono]} />
          </div>
        </div>
        <div className="mt-5 container_text_alert">
          <span className="fw-semibold">{estadoAlerta.message}</span>
        </div>
      </div>
    </div>
  )
}

export default Alertas
