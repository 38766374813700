/**
 * Restaurar password
 * @module RestaurarPassword
 * @author Fernando Olmos <fol@nowaii.com>
 */
import { useState } from "react"
import RestaurarPasswordUI from "./RestaurarPasswordUI"

/**
 * Restaurar Password
 * @function
 * @name RestaurarPassword
 * @property {Object} radios - Opciones de restauracion de password
 * @return {Component} Renderiza el componente encargado de la interfaz de usuario
 */
function RestaurarPassword() {
  /**
   * useState a traves del cual se valida la opcion con la que el usuario desea restaurar su password
   * @constant
   * @type {Array}
   * @name radioValue
   * @property {String} radioValue - Valor de useState
   * @property {Function} setRadioValue - Asignar valor al use state
   */
  const [radioValue, setRadioValue] = useState("1")
  /**
   * Array que almacena las maneras en que se puede restaurar el password
   * @constant
   * @type {Array}
   * @name radios
   * @property {String} radios.name - Nombre por defecto
   * @property {String} radios.value - Valor por defecto
   */
  const radios = [
    { name: "Por usuario", value: "1" },
    { name: "Por CURP", value: "2" },
  ]

  /**
   * Cambiar el valor del radio segun el click del usuario
   * @function
   * @name radioOnchange
   * @param {String} e - Evento retornado por el click
   */
  const radioOnchange = (e) => {
    setRadioValue(e.currentTarget.value)
  }

  return (
    <RestaurarPasswordUI
      radioValue={radioValue}
      radios={radios}
      radioOnchange={radioOnchange}
    />
  )
}

export default RestaurarPassword
