/**
 * Restaurar password UI
 * @module RestaurarPasswordCurpUI
 * @author Fernando Olmos <fol@nowaii.com>
 */

import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button"

/**
 * Verifica si la CURP ingresada es valida
 * @function
 * @name RestaurarPasswordUI
 * @param {Function} setCurp - Envia el valor ingresado para determinar si existe
 * @param {Boolean} curpValida - Valor de verificacion si la Curp ingresada es valida
 * @property {Boolean} usuarioDisponible - Valor useState que envia bandera de verificacion del usuario
 * @property {Function} setUsuarioDisponible - Funcion del useState que asigna la bandera de verificacion del usuario
 * @return {Component} Llama al componente que contiene la UI de restaurar por CURP
 */
function RestaurarPasswordUI({ setCurp, curpValida }) {
  const navigate = useNavigate()
  const [curpEmpleado, setCurpEmpleado] = useState("")
  const [disable, setDisable] = useState(true)
  const [disableCurpValidacion, setDisableCurpValidacion] = useState(true)

  /**
   * Evita que se recargue la pagina al enviar el formulario y envia el valor de la Curp para su evaluación
   * @function
   * @name onSubmit
   * @param {Object} event - Objeto generado por el evento submit
   */
  const onSubmit = (event) => {
    event.preventDefault()
    setCurp(curpEmpleado)
    navigate("/restaurar-password/verificar-token")
  }
  /**
   * Valida el length (18 caracteres) de la cadena ingresada, formatea en mayuscula el texto ingresado y lo envia el useState
   * @function
   * @name onChange
   * @param {Object} event - Objeto generado por el evento submit
   */
  const onChange = (event) => {
    if (event.target.value.length === 18) {
      if (curpValida(event.target.value)) {
        setDisable(false)
        setDisableCurpValidacion(true)
      } else {
        setDisableCurpValidacion(false)
        setDisable(true)
      }
    } else {
      setDisable(true)
    }
    setCurpEmpleado(event.target.value.toUpperCase())
  }
  return (
    <form onSubmit={onSubmit}>
      <div>
        <label htmlFor="inputCurp" className="fw-semibold">
          CURP
        </label>
        <div className="mt-2">
          <input
            className="input_authentication"
            maxLength="18"
            value={curpEmpleado}
            placeholder="Ingresa tu CURP porfavor"
            onChange={onChange}
            id="inputCurp"
          />
        </div>
        {!disableCurpValidacion && (
          <div className="mt-2 form_text_error">
            *Verifica que la CURP ingresada sea correcta
          </div>
        )}
      </div>
      <div className="mt-3 text-end">
        <Link
          to="/iniciar-sesion"
          className="d-inline-block fw-semibold link_normal"
        >
          Iniciar sesión
        </Link>
      </div>
      <div className="mt-3">
        <Button
          className="button_primary w-100"
          type="submit"
          disabled={disable}
        >
          Solicitar nueva contraseña
        </Button>
      </div>
    </form>
  )
}

export default RestaurarPasswordUI
