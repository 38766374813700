/**

Componente para cambiar la contraseña del usuario, se puede utilizar para cambiar la contraseña actual o para
asignar una nueva en caso de haberla olvidado.
@param {Boolean} restaurar - Indica si se está cambiando la contraseña por una olvidada o si es un cambio de contraseña
@returns JSX.Element
*/
import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEye,
  faEyeSlash,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import {
  addSesion,
  cargandoEvento,
} from "../../features/auth/autenticacionSlice"
import { addMessage, addEvent } from "../../features/actions/alertsSlice"
import {
  guardarLocalStorage,
  borrarLocalStorage,
  obtenerLocalStorage,
} from "../../utils/localStorage"
import { IniciarSesion } from "../../utils/AutenticacionAPI"
import { cambiarContrasenia } from "../../utils/CambiarPassword"
import { openModal, closeModal } from "../../utils/ControladorAlertas"
import Alertas from "../Alertas/Alertas"
import { setRestaurarPassword } from "../../utils/RestaurarPassword"

function CambiarPassword({ restaurar = false }) {
  const idUsuario = obtenerLocalStorage("RestaurarPaso1")
    ? obtenerLocalStorage("RestaurarPaso1").idUsuario
    : null
  const tokenUser = obtenerLocalStorage("RestaurarPaso2")
    ? obtenerLocalStorage("RestaurarPaso2").token
    : null
  const usuario = obtenerLocalStorage("RestaurarPaso2")
    ? obtenerLocalStorage("RestaurarPaso2")
    : null
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [contraseniaActual, setContraseniaActual] = useState("")
  const [mostrarActualContrasenia, setMostrarActualContrasenia] =
    useState(false)
  const [contrasenia, setContrasenia] = useState("")
  const [confirmarContrasenia, setConfirmarContrasenia] = useState("")
  const [mostrarContrasenia, setMostrarContrasenia] = useState(false)
  const [mostrarConfirmacionContrasenia, setMostrarConfirmacionContrasenia] =
    useState(false)
  const navigate = useNavigate()

  /**

Función para hacer el login una vez que se haya cambiado la contraseña
*/
  const getLogin = async () => {
    dispatch(cargandoEvento({ cargando: true }))
    const responseLogin = await IniciarSesion(usuario.user, contrasenia)
    if (responseLogin.token) {
      guardarLocalStorage("tokenUsuario", responseLogin.token)
      dispatch(addMessage({ type: "Success", message: "Acceso concedido" }))
      dispatch(addEvent({ show: true }))
      setTimeout(() => {
        dispatch(addEvent({ show: false }))
      }, 2500)
      borrarLocalStorage("RestaurarPaso1")
      borrarLocalStorage("RestaurarPaso2")
      dispatch(
        addSesion({
          cargando: false,
          autenticado: true,
          token: responseLogin.token,
        })
      )
    } else {
      setContrasenia("")
      dispatch(cargandoEvento({ cargando: false }))
      dispatch(
        addMessage({
          type: "Error",
          message:
            "No se puede iniciar sesión con las credenciales proporcionadas",
        })
      )
      dispatch(addEvent({ show: true }))
      setTimeout(() => {
        dispatch(addEvent({ show: false }))
        navigate("/iniciar-sesion")
      }, 2500)
    }
  }
  const resolverCambioContrasenia = async () => {
    setIsLoading(true)
    try {
      if (isLoading) {
        return
      }
      if (restaurar) {
        const respuesta = await setRestaurarPassword(
          idUsuario,
          contrasenia,
          tokenUser
        )
        if (!respuesta.error) {
          getLogin()
        }
        setIsLoading(false)
      } else {
        const res = await cambiarContrasenia(
          contraseniaActual,
          confirmarContrasenia
        )
        if (!res.error) {
          setContraseniaActual("")
          setContrasenia("")
          setConfirmarContrasenia("")
        }
        setIsLoading(false)
      }
    } catch (err) {
      openModal("Ha ocurrido un error al cambiar la contraseña", "Error")
      closeModal()
      setContraseniaActual("")
      setContrasenia("")
      setConfirmarContrasenia("")
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (restaurar) {
      if (idUsuario === null || tokenUser === null) {
        navigate("/")
      }
    }
  }, [idUsuario, tokenUser])
  return (
    <>
      {!restaurar && <Alertas />}
      <div
        className={!restaurar ? "complete_screen content_center_screen" : ""}
      >
        <div
          className={!restaurar ? "general_card_blur card_change_password" : ""}
        >
          {!restaurar && (
            <Link to="/panel" className="fw-semibold link_normal">
              <FontAwesomeIcon icon={faArrowLeft} />
              Ir a módulos
            </Link>
          )}
          <h1 className="fw-bold">
            {restaurar ? "Asignar nueva" : "Cambiar"} contraseña
          </h1>
          <p>Por favor ingresa y confirma tus nuevos datos</p>
          {!restaurar && (
            <div>
              <label htmlFor="inputContraseniaActual" className="fw-semibold">
                Contraseña actual
              </label>
              <div className="mt-2 position-relative">
                <input
                  className="input_authentication"
                  type={mostrarActualContrasenia ? "text" : "password"}
                  value={contraseniaActual}
                  onChange={(event) => setContraseniaActual(event.target.value)}
                  id="inputContraseniaActual"
                  placeholder="Ingresa tu contraseña actual"
                />
                <button
                  className="button_icon button_icon_password"
                  onClick={() =>
                    setMostrarActualContrasenia(!mostrarActualContrasenia)
                  }
                >
                  <FontAwesomeIcon
                    icon={mostrarActualContrasenia ? faEye : faEyeSlash}
                    style={{
                      color: mostrarActualContrasenia ? "#E33333" : "#585858",
                    }}
                  />
                </button>
              </div>
            </div>
          )}
          <div className="mt-2">
            <label htmlFor="inputContrasenia" className="fw-semibold">
              Nueva contraseña
            </label>
            <div className="mt-2 position-relative">
              <input
                className="input_authentication"
                type={mostrarContrasenia ? "text" : "password"}
                value={contrasenia}
                onChange={(event) => setContrasenia(event.target.value)}
                id="inputContrasenia"
                placeholder="Ingresa tu nueva contraseña"
              />
              <button
                className="button_icon button_icon_password"
                onClick={() => setMostrarContrasenia(!mostrarContrasenia)}
              >
                <FontAwesomeIcon
                  icon={mostrarContrasenia ? faEye : faEyeSlash}
                  style={{ color: mostrarContrasenia ? "#E33333" : "#585858" }}
                />
              </button>
            </div>
          </div>
          <div className="mt-2">
            <label htmlFor="inputConfirmarContrasenia" className="fw-semibold">
              Confirmar nueva contraseña
            </label>
            <div className="mt-2 position-relative">
              <input
                className="input_authentication"
                type={mostrarConfirmacionContrasenia ? "text" : "password"}
                value={confirmarContrasenia}
                onChange={(event) =>
                  setConfirmarContrasenia(event.target.value)
                }
                id="inputConfirmarContrasenia"
                placeholder="Confirma tu nueva contraseña"
              />
              <button
                className="button_icon button_icon_password"
                onClick={() =>
                  setMostrarConfirmacionContrasenia(
                    !mostrarConfirmacionContrasenia
                  )
                }
              >
                <FontAwesomeIcon
                  icon={mostrarConfirmacionContrasenia ? faEye : faEyeSlash}
                  style={{
                    color: mostrarConfirmacionContrasenia
                      ? "#E33333"
                      : "#585858",
                  }}
                />
              </button>
            </div>
          </div>
          <div className="mt-5">
            <button
              className="button_primary w-100"
              disabled={
                restaurar
                  ? isLoading
                    ? true
                    : contrasenia === "" || confirmarContrasenia === ""
                    ? true
                    : contrasenia !== confirmarContrasenia
                  : isLoading
                  ? true
                  : contraseniaActual === "" ||
                    contrasenia === "" ||
                    confirmarContrasenia === ""
                  ? true
                  : contrasenia !== confirmarContrasenia
              }
              // disabled={isLoading?true:contraseniaActual === "" ||contrasenia === "" || confirmarContrasenia === "" ? true : contrasenia === confirmarContrasenia ? false : true}
              onClick={() => resolverCambioContrasenia()}
            >
              {isLoading ? (
                <FontAwesomeIcon
                  className="fa-spin"
                  icon={["fas", "fa-circle-notch"]}
                />
              ) : (
                "Realizar cambio"
              )}
            </button>
          </div>
          {restaurar && (
            <div className="mt-3 text-center">
              <Link to="/iniciar-sesion" className="d-inline-block link_normal">
                <span className="d-block not_action">
                  ¿No deseas asignar nueva contraseña?
                </span>
                Iniciar sesión
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CambiarPassword
