/**
 * Componente para la búsqueda de modulos
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module Buscador
 */

import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons"

/**
 * Recibe como parametro las props para definir el estado del nombre de la búsqueda
 * @function
 * @name Buscador
 * @property {String} textoBusqueda - Valor useState que recibe entrada de texto para el nombre del modulo
 * @property {Function} setTextoBusqueda - Funcion del useState que obtiene el nombre del modulo
 */
function Buscador({ textoBusqueda, setTextoBusqueda }) {
  const [mostrarIconoBorrarBusqueda, setMostrarIconoBorrarBusqueda] =
    useState(false)

  /**
   * Obtiene el texto de la búsqueda para enviar a traves de un estado y mostrar u ocultar boton de limpiar búsqueda
   * @function
   * @name resolverBuscarModulo
   * @param {Object} event - Objeto generado por el evento onChange
   */
  const resolverBuscarModulo = (event) => {
    const valorInput = event.target.value
    setTextoBusqueda(valorInput)

    if (valorInput.length >= 1) {
      setMostrarIconoBorrarBusqueda(true)
    } else {
      setMostrarIconoBorrarBusqueda(false)
    }
  }

  /**
   * Borra el texto de búsqueda ingresado
   * @function
   * @name resolverLimpiarBusqueda
   */
  const resolverLimpiarBusqueda = () => {
    setTextoBusqueda("")
    setMostrarIconoBorrarBusqueda(false)
  }

  return (
    <section className="d-flex justify-content-center container_search">
      <div className="container_input_search">
        <div className="content_input_search">
          <FontAwesomeIcon icon={faSearch} className="input_icon_search" />
          <input
            type="text"
            placeholder="Buscar módulo"
            className="input_search input_search_icon"
            value={textoBusqueda}
            onChange={resolverBuscarModulo}
          />
          {mostrarIconoBorrarBusqueda && (
            <button
              onClick={resolverLimpiarBusqueda}
              className="input_icon_close"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default Buscador
