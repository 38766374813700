/**
 * Restaurar password - user
 * @module RestaurarPasswordUser
 * @author Fernando Olmos <fol@nowaii.com>
 */
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import RestaurarPasswordUI from "./RestaurarPasswordUserUI"
import { solicitarTokenRestaurarPassword } from "../../../utils/RestaurarPassword"
import { guardarLocalStorage } from "../../../utils/localStorage"
import { addMessage, addEvent } from "../../../features/actions/alertsSlice"

/**
 * Envia props necesarias para dibujar la interfaz del componente RestaurarPasswordUI
 * @function
 * @name RestaurarPasswordUser
 * @property {Function} setUser - useState que almacena el usuario que quiere restaurar password
 * @property {Function} setUsuarioDisponible - funcion del useState para setear el valor del usuario
 * @return {Component} Llama al componente que contiene la UI de restaurar por Usuario
 */
function RestaurarPasswordUser() {
  const [emailEmpleado, setEmailEmpleado] = useState("")
  const [disable, setDisable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const regexEmail = /^[\w%]+(\.[\w%]+)*@[\w%]+(\.[\w%]+)+$/
  const historyRedirigir = useNavigate()
  const dispatch = useDispatch()

  /**
   * Evalua si el usuario existe para restaurar su password (Funcion en construccion)
   * @function
   * @name setUser
   * @param {String} event - Recibe el usuario
   */

  const handleEnviarEmail = async (emailEmpleado) => {
    setIsLoading(true)
    setDisable(true)
    try {
      const respuesta = await solicitarTokenRestaurarPassword(emailEmpleado)
      if (respuesta.error) {
        setDisable(false)
        setIsLoading(false)
      } else {
        historyRedirigir("/restaurar-password/verificar-token/")
        guardarLocalStorage("RestaurarPaso1", { idUsuario: respuesta.data.id })
      }
    } catch (error) {
      setDisable(false)
      setIsLoading(false)
    }
  }
  const onSubmit = (event) => {
    event.preventDefault()
    setEmailEmpleado(emailEmpleado)
    if (regexEmail.test(emailEmpleado)) {
      handleEnviarEmail(emailEmpleado)
    } else {
      dispatch(
        addMessage({
          type: "Error",
          message: `${emailEmpleado} no es una direccion valida`,
        })
      )
      dispatch(addEvent({ show: true }))
      setTimeout(() => {
        dispatch(addEvent({ show: false }))
      }, 2500)
      setEmailEmpleado("")
    }
  }
  /**
   * Evalua las caracteristicas de la cadena ingresada
   * @function
   * @name onChange
   * @param { String } event - Recibe la entrada dada por el usuario
   */
  const onChange = (event) => {
    if (event.target.value.length > 5) {
      setDisable(false)
    } else {
      setDisable(true)
    }
    setEmailEmpleado(event.target.value)
  }
  return (
    <RestaurarPasswordUI
      emailEmpleado={emailEmpleado}
      disable={disable}
      isLoading={isLoading}
      onSubmit={onSubmit}
      onChange={onChange}
    />
  )
}

export default RestaurarPasswordUser
