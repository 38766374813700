/**

Hook personalizado que devuelve el valor previo de una variable.
@typedef {Object} PropsInputOTP
@property {number} length - Cantidad de inputs que se deben mostrar.
@property {boolean} isNumberInput - Define si los inputs son de tipo number.
@property {boolean} autoFocus - Define si el primer input debe estar enfocado al cargar el componente.
@property {boolean} disabled - Define si los inputs están deshabilitados.
@property {string} className - Clase CSS que se aplica al contenedor del componente.
@property {string} inputClassName - Clase CSS que se aplica a cada input del componente.
@property {Object} inputStyle - Estilo CSS que se aplica a cada input del componente.
@property {function} onChangeOTP - Función que se ejecuta cuando cambia el valor del input.
@property {function} resolverConfirmarTokenEnter - Función que se ejecuta cuando se presiona la tecla Enter en el último input.
*/

import { useState, useCallback } from "react"
import SingleInput from "./SingleInput"

/**

Componente InputOTP que muestra varios inputs para ingresar un código OTP.
@function InputOTP
@param {PropsInputOTP} props - Props del componente.
@returns {JSX.Element} - El componente InputOTP.
*/
function InputOTP({
  length,
  isNumberInput,
  autoFocus,
  disabled,
  className,
  onChangeOTP,
  inputClassName,
  inputStyle,
  resolverConfirmarTokenEnter,
  ...rest
}) {
  const [activeInput, setActiveInput] = useState(0)
  const [otpValues, setOTPValues] = useState(new Array(length).fill(""))
  const handleOtpChange = useCallback(
    (otp) => {
      const otpValue = otp.join("")
      onChangeOTP(otpValue)
    },
    [onChangeOTP]
  )

  const getRightValue = useCallback(
    (str) => {
      const changedValue = str
      if (!isNumberInput || !changedValue) {
        return changedValue
      }

      return Number(changedValue) >= 0 ? changedValue : ""
    },
    [isNumberInput]
  )

  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues]
      updatedOTPValues[activeInput] = str[0] || ""
      setOTPValues(updatedOTPValues)
      handleOtpChange(updatedOTPValues)
    },
    [activeInput, handleOtpChange, otpValues]
  )

  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0)
      setActiveInput(selectedIndex)
    },
    [length]
  )

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1)
  }, [activeInput, focusInput])

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1)
  }, [activeInput, focusInput])

  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index)
    },
    [focusInput]
  )

  const handleOnChange = useCallback(
    (event) => {
      const value = getRightValue(event.currentTarget.value)
      if (!value) {
        event.preventDefault()
        return
      }
      changeCodeAtFocus(value)
      focusNextInput()
    },
    [changeCodeAtFocus, focusNextInput, getRightValue]
  )

  const onBlur = useCallback(() => {
    setActiveInput(-1)
  }, [])

  const handleOnKeyDown = useCallback(
    (event) => {
      const pressedKey = event.key
      switch (pressedKey) {
        case "Backspace":
        case "Delete": {
          event.preventDefault()
          if (otpValues[activeInput]) {
            changeCodeAtFocus("")
          } else {
            focusPrevInput()
          }
          break
        }
        case "ArrowLeft": {
          event.preventDefault()
          focusPrevInput()
          break
        }
        case "ArrowRight": {
          event.preventDefault()
          focusNextInput()
          break
        }
        default: {
          if (pressedKey.match(/^[^a-zA-Z0-9]$/)) {
            event.preventDefault()
          }
          break
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  )

  const handleOnPaste = useCallback(
    (event) => {
      event.preventDefault()
      const pasteData = event.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, length - activeInput)
        .split("")
      if (pasteData) {
        let nextFocusIndex = 0
        const updatedOTPValues = [...otpValues]
        updatedOTPValues.forEach((value, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pasteData.shift() || value)
            if (changedValue) {
              updatedOTPValues[index] = changedValue
              nextFocusIndex = index
            }
          }
        })
        setOTPValues(updatedOTPValues)
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1))
        handleOtpChange(updatedOTPValues)
      }
    },
    [activeInput, getRightValue, length, otpValues]
  )
  return (
    <div className={className}>
      {Array(length)
        .fill("")
        .map((_, index) => (
          <SingleInput
            key={`SingleInput-${index}`}
            type={isNumberInput ? "number" : "text"}
            focus={activeInput === index}
            value={otpValues && otpValues[index]}
            autoFocus={autoFocus}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={onBlur}
            onKeyUp={resolverConfirmarTokenEnter}
            onPaste={handleOnPaste}
            style={inputStyle}
            className={inputClassName}
            disabled={disabled}
          />
        ))}
    </div>
  )
}

export default InputOTP
