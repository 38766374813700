import RutasAplicacion from "./routes/RutasAplicacion"
import "./styles/scss/App.scss"
import moment from "moment"
import "moment/locale/es"
moment.locale("es")

function App() {
  return <RutasAplicacion />
}

export default App
