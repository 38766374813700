import { customFetch } from "../mixinFetch"

/**

Realiza una solicitud GET al endpoint de tokens de notificación de la API.
@async
@function
@returns {Promise<Object>} Objeto que contiene los tokens de notificación.
*/
export const getTokens = async () =>
  await customFetch(`${process.env.REACT_APP_API_URL}cuenta/notifycate`)

/**

Realiza una solicitud DELETE al endpoint de tokens de notificación de la API para eliminar tokens.
@async
@function
@param {Object} params - Parámetros a enviar con la solicitud DELETE.
@returns {Promise<Object>} Objeto que contiene la respuesta de la API.
*/
export const deleteTokens = async (params) =>
  await customFetch(
    `${process.env.REACT_APP_API_URL}cuenta/notifycate`,
    params,
    "DELETE",
    true
  )
