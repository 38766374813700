/**

Módulo para manejar notificaciones usando Firebase Cloud Messaging
@module FirebaseNotificacion
*/

import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import { store } from "../../app/Store"
import {
  addEventNotification,
  addTitle,
  addDescription,
} from "../../features/Notificacion"
import { guardarLocalStorage, obtenerLocalStorage } from "../localStorage"
import { customFetch } from "../mixinFetch"

/**

Configuración de Firebase para la aplicación
@type {Object}
@property {string} apiKey - Clave de API de Firebase
@property {string} authDomain - Dominio de autenticación de Firebase
@property {string} projectId - ID del proyecto de Firebase
@property {string} storageBucket - Bucket de almacenamiento de Firebase
@property {string} messagingSenderId - ID del sender de Firebase Cloud Messaging
@property {string} appId - ID de la aplicación de Firebase
@property {string} measurementId - ID de medición de Firebase
*/
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

/**

Objeto de la aplicación de Firebase
@type {Object}
*/
const firebaseApp = initializeApp(firebaseConfig)

/**

Objeto para acceder a Firebase Cloud Messaging
@type {Object}
*/
const messaging = getMessaging(firebaseApp)
// let checkSingleton = false;

/**

Función para mostrar una notificación
@async
@function
@param {string} title - Título de la notificación
@param {string} description - Descripción de la notificación
*/
export const openNotification = async (title, description) => {
  store.dispatch(addTitle({ title }))
  store.dispatch(addDescription({ description }))
  store.dispatch(addEventNotification({ show: true }))

  closeNotificationTime()
}

export const closeNotification = async () => {
  store.dispatch(addEventNotification({ show: false }))
}

/**

Función para cerrar una notificación
@async
@function
*/
export const closeNotificationTime = async () => {
  setTimeout(() => {
    store.dispatch(addEventNotification({ show: false }))
  }, 5000)
}

/**

Función para obtener el token del usuario
@function
@param {function} setTokenFound - Función para establecer si se encontró el token
*/
export const getTokenUser = (setTokenFound) =>
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true)
        // if (!checkSingleton) {
        singletonTokenNotification(currentToken)
        // checkSingleton = true;
        // }
      } else {
        setTokenFound(false)
      }
    })
    .catch(() => {
      setTokenFound(false)
    })

/**

Función para escuchar cuando llega una notificación
@function
@returns {Promise<Object>} - Promesa con el payload de la notificación
*/
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })

/**

Función que verifica si el token de notificación actual es diferente al almacenado en el LocalStorage y, de ser así,
realiza una solicitud para almacenar el nuevo token en el servidor.
@param {string} currentToken - Token de notificación actual.
*/
const singletonTokenNotification = (currentToken) => {
  if (obtenerLocalStorage("notification") !== currentToken) {
    const sesionNavigator = obtenerIdNavegador()
    almacenarToken(currentToken, sesionNavigator)
    guardarLocalStorage("notification", currentToken)
  }
}

/**

Función que realiza una solicitud POST al servidor para almacenar un token de notificación junto con información del navegador del usuario.
@param {string} token - Token de notificación.
@param {string} navegador - Información del navegador del usuario.
@returns {Promise<Response>} - Promesa que se resuelve en una respuesta de la solicitud fetch.
*/
const almacenarToken = async (token, navegador) =>
  await customFetch(
    `${process.env.REACT_APP_API_URL}cuenta/notifycate`,
    { token, type: "web", browser_data: navegador },
    "POST",
    false
  )

/**

Función que obtiene el nombre del navegador del usuario a partir del agente de usuario del navegador.
@returns {string} - Nombre del navegador del usuario.
*/
const obtenerIdNavegador = () => {
  let sBrowser
  const sUsrAg = navigator.userAgent
  if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari"
  } else if (sUsrAg.indexOf("Opera") > -1) {
    sBrowser = "Opera"
  } else if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox"
  } else if (sUsrAg.indexOf("MSIE") > -1) {
    sBrowser = "Microsoft Internet Explorer"
  } else if (sUsrAg.indexOf("edg/") > -1) {
    sBrowser = "Microsoft Edge"
  } else {
    sBrowser = "Navegador no identificado"
  }
  return sBrowser
}
