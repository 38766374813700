/**
 * Fetch a cambio de contraseña pasando parametros
 * @module CambioContraseña
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import { customFetch } from "./mixinFetch"
/**
 * Recibe la actual contraseña y la nueva para realizar el cambio
 * @async
 * @function
 * @name cambiarContrasenia
 * @param {String} actualPassword - Contraseña actual
 * @param {String} newPassword - Nueva contraseña
 * @return {Object}
 */
export const cambiarContrasenia = async (actualPassword, newPassword) =>
  await customFetch(
    `${process.env.REACT_APP_API_URL}cuenta/password`,
    { password_old: actualPassword, password_new: newPassword },
    "POST",
    true
  )
