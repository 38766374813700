/**
 * Componente para la implementacion de cards en skeleton cargando
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module Skeleton
 */

/**
 * Recibe como parametro las props para definir la estructura de la skeleton card
 * @function
 * @name Skeleton
 * @param {String} tipo - Tipo de componente que utilizara card skeleton
 * @param {Number} numCards - Numero de skeleton card que utilizara el componente
 * @param {Number} widthCard - Valor del tamaño de ancho de la skeleton card
 * @param {Number} heightCard - Valor del tamaño de alto de la skeleton card
 */

function Skeleton({ tipo, numCards, widthCard, heightCard }) {
  const cards = []

  /**
   * Evalua el tipo de componente que renderizara el skeleton
   * @param {String} tipo - Recibe el tipo de componente que utilizara skeleton card
   * @return {Array} Retorna un arreglo de skeleton cards
   */
  switch (tipo) {
    case "Bar": {
      for (let i = 1; i <= numCards; i++) {
        cards.push(
          <div
            key={i}
            className="card_skeleton card_skeleton_animated skeleton_bar"
            style={{ width: widthCard, height: heightCard }}
          />
        )
      }
      return cards
    }
    case "Bar nombre perfil": {
      return (
        <div
          className="card_skeleton card_skeleton_animated skeleton_bar skeleton_perfil_name"
          style={{ height: heightCard }}
        />
      )
    }
    case "Modulos": {
      for (let i = 1; i <= numCards; i++) {
        cards.push(
          <div
            key={i}
            className="card_skeleton card_skeleton_animated card_skeleton_main"
            style={{ width: "100%", height: heightCard }}
          >
            <div className="d-flex justify-content-center">
              <div className="card_skeleton_internal card_skeleton_animated skeleton_icon" />
            </div>
            <div className="mt-2">
              <div className="card_skeleton_internal card_skeleton_animated skeleton_bar w-100" />
            </div>
            <div className="mt-2">
              <div className="card_skeleton_internal card_skeleton_animated skeleton_bar w-100" />
            </div>
          </div>
        )
      }
      return cards
    }
  }
}

export default Skeleton
