/**

Componente de notificación.
Este componente muestra una notificación que se puede mostrar y ocultar. Contiene un título, una descripción y una imagen de notificación.
@param {Object} props - Las props del componente.
@param {boolean} props.showNotification - Indica si la notificación debe mostrarse o no.
@param {string} props.title - El título de la notificación.
@param {string} props.description - La descripción de la notificación.
@returns {JSX.Element} Componente JSX que muestra la notificación.
*/

import { Link } from "react-router-dom"
import ImagenNotificacion from "../../assets/img/artboard_123063.png"

export const Notificacion = ({ showNotification, title, description }) => {
  return (
    <>
      <div
        className={`notification_ui ${
          showNotification
            ? "show_notification_ui notification_ui_animation"
            : ""
        }`}
      >
        <Link to="/" className="notification_item">
          <div>
            <div
              className={`notification_img_content ${
                showNotification ? "notification_img_content_animation" : ""
              }`}
            >
              <img src={ImagenNotificacion} alt="Imagen notificacion" />
            </div>
          </div>
          <div>
            <h1
              className={`mb-0 notification_title ${
                showNotification ? "notification_title_animation" : ""
              }`}
            >
              {title}
            </h1>
            <p
              className={`mb-0 notification_description ${
                showNotification ? "notification_description_animation" : ""
              }`}
            >
              {description}
            </p>
          </div>
        </Link>
      </div>
    </>
  )
}

export default Notificacion
