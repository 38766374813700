/**
 * Borrado de sesion del usuario
 * @module CerrarSesion
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import { closeSesion } from "../features/auth/autenticacionSlice"
import { borrarLocalStorage } from "./localStorage"
import { store } from "../app/Store"
/**
 * Borrado de datos dentro del localStorage y en los states de la aplicación
 * @async
 * @function
 * @name LogOut
 */
export const LogOut = async () => {
  try {
    // const resCerrarSesion = await CerrarSesionAPI(autenticado.token);
    // if(resCerrarSesion.status === 'success'){
    borrarLocalStorage("tokenUsuario")
    borrarLocalStorage("dataUser")
    store.dispatch(
      closeSesion({ cargando: false, autenticado: false, token: "" })
    )
    // }else{
    // console.log("Ha ocurrido un error al cerrar sesion",resCerrarSesion);
    // }
  } catch (error) {
    alert("Error al cerrar sesion")
  }
}
