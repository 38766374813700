/**
 * Validacion de autenticacion para mostrar rutas privadas
 * @module RutasAutenticacion
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import { useEffect } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  verificarSesion,
  cargandoEvento,
} from "../features/auth/autenticacionSlice"
import Cargando from "../components/Cargando/Cargando"

function RutasAutenticacion() {
  /**
   * Obtiene el valor del state del login y asigna a la variable
   * @function
   * @name useSelector
   */
  const autenticado = useSelector((state) => state.login)
  const dispatch = useDispatch()
  useEffect(() => {
    /**
     * Verifica la existencia del token
     * @async
     * @function
     * @name resolverValidarSesion
     */
    const resolverValidarSesion = async () => {
      try {
        if (autenticado.token !== "") {
          dispatch(verificarSesion({ load: false, autenticado: true }))
        } else {
          dispatch(verificarSesion({ load: false, autenticado: false }))
        }
      } catch (e) {}
    }
    dispatch(cargandoEvento({ cargando: true }))
    resolverValidarSesion()
  }, [dispatch, autenticado.token])
  if (autenticado.cargando) {
    return <Cargando />
  }
  if (!autenticado.autenticado) {
    return <Navigate to="/iniciar-sesion" />
  }
  return <Outlet />
}

export default RutasAutenticacion
