/**
 * Manejo centralizado del localstorage
 * @module Localstorage
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import ls from "localstorage-slim"
/**
 * Guardado del localstorage encriptado con localstorage-slim
 * @function
 * @name guardarLocalStorage
 * @param {String} llave - Identificador por el cual sera identificado
 * @param {Object} valor - Objecto a guardar puede ser un string tambien
 */
export const guardarLocalStorage = (llave, valor) => {
  try {
    ls.set(llave, JSON.stringify(valor), { encrypt: true })
  } catch (error) {}
}
/**
 * Obtener datos guardados en el localstorage
 * @function
 * @name obtenerLocalStorage
 * @param {String} llave - Identificador del objeto
 * @returns {Object}
 */
export const obtenerLocalStorage = (llave) => {
  try {
    const valor = JSON.parse(ls.get(llave, { decrypt: true }))
    return valor
  } catch (error) {
    borrarLocalStorage(llave)
  }
}
/**
 * Borrado del localstorage
 * @function
 * @name borrarLocalStorage
 * @param {String} llave - Identificador del objeto
 */
export const borrarLocalStorage = (llave) => {
  try {
    window.localStorage.removeItem(llave)
  } catch (error) {}
}

/**
 * Limpiar el localstorage al iniciar sesion
 * @function
 * @name limpiarLocalStorage
 */

export const limpiarLocalStorage = () => {
  const keys = ["TokenUsuario", "notificacion", "dataUser"]
  const localData = Object.keys(localStorage)
  localData.map((item) => {
    return !keys.includes(item) ? borrarLocalStorage(item) : item
  })
}

export const limpiarSesionStorage = () => {
  const keys = ["tokenUsuario", "notification", "dataUser"]
  const localData = Object.keys(localStorage)
  localData.forEach((item) => {
    if (!keys.includes(item)) {
      borrarLocalStorage(item)
    }
  })
}
