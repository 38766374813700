import { eliminarAcentos } from ".."
import { customFetch } from "../mixinFetch"

const API_URL = process.env.REACT_APP_API_URL + "data/mensajes"

export const getDocumentacion = async (params) => {
    return await customFetch(API_URL, params)
}

export const handleGetDocumentacion = async (setDocumentacion) => {
    const params = {
        model: "modulo",
        type: "Documentacion",
        id: 6,
        app_label: "sistema",
        estado: "True",
        aprobado: "Aprobada",
    }
    const response = await getDocumentacion(params)
    if (!response?.error) {
        if (response?.data?.length > 0) {
            setDocumentacion({
                isLoading: false,
                data: {
                    icono: "book",
                    modulo_id: 0,
                    nombre: response?.data[0]?.asunto,
                    prefix: "fas",
                    roles: [],
                    ruta: response?.data[0]?.mensaje,
                    slug: eliminarAcentos(response?.data[0]?.asunto.replace(/\s/g, "-").toLowerCase()),
                }
            })
        }
    }
}
