/**
 * Componente principal del panel
 * @module PanelMenu
 */
import { useState, useEffect } from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { obtenerInformacionUsuario } from "./features/auth/usuarioSlice"
import Header from "./components/Panel/Header"
import Buscador from "./components/Panel/Buscador"
import ModuloNoEncontrado from "./components/Panel/Modulos/ModuloNoEncontrado"
import ModulosLista from "./components/Panel/Modulos/ModulosLista"
import Modulo from "./components/Panel/Modulos/Modulo"
import Alertas from "./components/Alertas/Alertas"
import Skeleton from "./components/Skeleton/Skeleton"
import BoundaryErrorModulos from "./components/Errors/BoundaryErrorModulos"
import { handleGetDocumentacion } from "./utils"

library.add(fas, fab)

function PanelMenu() {
  /**
   * Obtiene informacion del state y la asigna a la variable
   * @function
   * @name useSelector
   */
  const modulosUsuario = useSelector((state) => state.usuario)
  const dispatch = useDispatch()
  const [documentacion, setDocumentacion] = useState({ isLoading: true, data: {} })
  let modulos = []
  if (modulosUsuario.user.permisos) {
    modulos = modulosUsuario.user.permisos.map((item) => item)
  }
  // modulos.push({
  //   icono: "user",
  //   modulo_id: 0,
  //   nombre: "Descriptor",
  //   prefix: "fas",
  //   roles: [],
  //   ruta: "app/rrhh/descriptor/",
  //   slug: "RRHH-descriptor",
  // });
  const [textoBusqueda, setTextoBusqueda] = useState("")
  let modulosTotales = []
  if (!textoBusqueda.length >= 1) {
    modulosTotales = modulos
  } else {
    /**
     * Funcion para eliminar acentos al texto de búsqueda
     * @function
     * @name eliminarAcentos
     * @param { String } cadena - Recibe el texto del input de busqueda y el nombre del modulo desde la respuesta
     * @return { String } Retorna el nombre de la busqueda ignorando acentos
     */
    const eliminarAcentos = (cadena) => {
      const acentos = {
        á: "a",
        é: "e",
        í: "i",
        ó: "o",
        ú: "u",
        Á: "A",
        É: "E",
        Í: "I",
        Ó: "O",
        Ú: "U",
      }
      return cadena
        .split("")
        .map((letra) => acentos[letra] || letra)
        .join("")
        .toString()
    }

    /**
     * Metodo para filtrar busqueda
     * @callback modulosTotales
     * @typedef {Object} modulos - Recibe el objeto que contiene los datos del modulo
     * @property {String} modulos[].nombre - Valor del nombre del modulo
     * @return {Boolean} Retorna verdadero o falso si el nombre del modulo es semejante a la entrada del texto de busqueda y hace el filtrado
     */
    modulosTotales = modulos.filter((item) => {
      const nombreModulo = eliminarAcentos(item.nombre)
        .toLowerCase()
        .split(" ")
        .join("")
      const entradaBusquedaModulo = eliminarAcentos(textoBusqueda)
        .toLowerCase()
        .split(" ")
        .join("")

      return nombreModulo.includes(entradaBusquedaModulo)
    })
  }

  useEffect(() => {
    /**
     * Llama a la funcion payloadCreator
     * @function
     * @name dispatch
     * @author Jesus Gonzalez <jgp@nowaii.com>
     */
    handleGetDocumentacion(setDocumentacion)
    dispatch(obtenerInformacionUsuario("usuario"))
  }, [dispatch])

  return (
    <>
      <Alertas />
      <Header />

      <Buscador
        textoBusqueda={textoBusqueda}
        setTextoBusqueda={setTextoBusqueda}
      />
      {!modulosUsuario.isLoading ? (
        <>
          {!modulosTotales.length && <ModuloNoEncontrado />}
          <BoundaryErrorModulos>
            <ModulosLista>
              <>
                {!documentacion?.isLoading && (
                  <Modulo
                    key={`${documentacion?.data?.slug}-${documentacion?.data?.id}`}
                    iconPrefix={documentacion?.data?.prefix}
                    iconName={documentacion?.data?.icono}
                    name={documentacion?.data?.nombre}
                    nameEnterprise={documentacion?.data?.slug}
                    route={documentacion?.data?.ruta}
                    typeRoute={"blank"}
                  />
                )}
                {modulosTotales.map((modulo) => (
                  <Modulo
                    key={`${modulo.slug}-${modulo.id}`}
                    iconPrefix={modulo.prefix}
                    iconName={modulo.icono}
                    name={modulo.nombre}
                    nameEnterprise={modulo.slug}
                    route={!modulo?.mantenimiento_web ? modulo.ruta : `#/mantenimiento/${btoa(modulo?.segundos_restantes_web)}`}
                    mantenimiento={modulo?.mantenimiento_web}
                  />
                ))}
              </>
            </ModulosLista>
          </BoundaryErrorModulos>
        </>
      ) : (
        <ModulosLista>
          <Skeleton
            tipo="Modulos"
            numCards={40}
            widthCard={150}
            heightCard={140}
          />
        </ModulosLista>
      )}
    </>
  )
}

export default PanelMenu
