/**

Clase que representa un componente React de manejo de errores para modulos no encontrados.
@class
@extends React.Component
*/

import React from "react"
import ImagenModuloNoEncontrado from "../../../assets/img/no_encontrados_modulos.png"

class BoundaryErrorModulos extends React.Component {
  constructor(props) {
    super(props)
    this.state = { tieneError: false, mensajeError: "" }
  }

  /**

Método que permite actualizar el estado del componente cuando ocurre un error en los componentes hijos.
@static
@param {Error} error - Error que se ha producido en los componentes hijos.
@returns {object} - Un objeto que actualiza el estado del componente.
*/
  static getDerivedStateFromError(error) {
    // Método 1
    return { tieneError: true, mensajeError: error.message }
  }

  /**

  Método que se ejecuta cuando ocurre un error en los componentes hijos.
  @param {Error} error - Error que se ha producido en los componentes hijos.
  @returns {Error} - El mismo error que se ha producido.
  */
  componentDidCatch(error) {
    // Método 2
    // Ambos sirven por igual
    return error
  }

  render() {
    if (this.state.tieneError) {
      return (
        <div className="container_module_not_found">
          <div className="d-flex justify-content-center">
            <img
              src={ImagenModuloNoEncontrado}
              alt="Modulo No Encontrado"
              className="image_module_not_found"
            />
          </div>
          <div className="mt-3">
            <p className="text_module_not_found mb-0">
              Ha ocurrido un error al consultar los modulos
            </p>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default BoundaryErrorModulos
