import { customFetch } from "./mixinFetch"

const API_URL = process.env.REACT_APP_API_URL

export const solicitarTokenRestaurarPassword = async (email) =>
  await customFetch(`${API_URL}auth/recuperar`, { email }, "POST", true)

export const validarTokenRestaurarPassword = async (id, codigoConfirmacion) =>
  await customFetch(
    `${API_URL}auth/solicitar_token`,
    { id, codigo_confirmacion: codigoConfirmacion },
    "PUT",
    true
  )

export const setRestaurarPassword = async (id, passwordNew, token) =>
  await customFetch(
    `${API_URL}auth/cambio_password`,
    { id, password_new: passwordNew, token },
    "POST",
    true
  )
