import { configureStore } from "@reduxjs/toolkit"
import autenticacionReducer from "../features/auth/autenticacionSlice"
import usuarioReducer from "../features/auth/usuarioSlice"
import alertsReducer from "../features/actions/alertsSlice"
import restaurarReducer from "../features/RestaurarPassword"
import notificacionReducer from "../features/Notificacion"
import TokensReducer from "../features/Token"
import alertConfirmReducer from "../features/Confirm"

export const store = configureStore({
  reducer: {
    login: autenticacionReducer,
    usuario: usuarioReducer,
    alert: alertsReducer,
    restaurarPassword: restaurarReducer,
    notification: notificacionReducer,
    tokens: TokensReducer,
    confirm: alertConfirmReducer,
  },
})
