/**

Este archivo exporta un slice de Redux que contiene acciones para mostrar y ocultar alertas en la aplicación.
@module confirmAlertSlice
*/

import { createSlice } from "@reduxjs/toolkit"

/**

Estado inicial del slice de Redux que contiene las variables para controlar las alertas.
@type {object}
@property {boolean} showModalAlert - Booleano que indica si la alerta general debe ser mostrada.
@property {boolean} showModalAlertEmpresas - Booleano que indica si la alerta de empresas debe ser mostrada.
@property {object} itemSelected - Objeto que contiene el elemento seleccionado en la alerta.
*/
const initialState = {
  showModalAlert: false,
  showModalAlertEmpresas: false,
  itemSelected: {},
}

/**

Slice de Redux que contiene las acciones para actualizar las variables del estado de las alertas.
@type {object}
*/
export const confirmAlertSlice = createSlice({
  name: "confirmAlert",
  initialState,
  reducers: {
    /**
      Acción que actualiza el estado de la variable showModalAlert para mostrar u ocultar la alerta general.
      @function
      @param {object} state - Estado actual del slice de Redux.
      @param {object} action - Acción que contiene el valor booleano para actualizar la variable showModalAlert.
      @returns {object} Nuevo estado actualizado con el valor de showModalAlert actualizado.
    */
    addEvent: (state, action) => ({
      ...state,
      showModalAlert: action.payload.status,
    }),

    /**
      Acción que actualiza el estado de la variable showModalAlertEmpresas para mostrar u ocultar la alerta de empresas.
      @function
      @param {object} state - Estado actual del slice de Redux.
      @param {object} action - Acción que contiene el valor booleano para actualizar la variable showModalAlertEmpresas.
      @returns {object} Nuevo estado actualizado con el valor de showModalAlertEmpresas actualizado.
    */
    addEventEmpresas: (state, action) => ({
      ...state,
      showModalAlertEmpresas: action.payload.status,
    }),

    /**
      Acción que actualiza el estado de la variable itemSelected para agregar el elemento seleccionado en la alerta.
      @function
      @param {object} state - Estado actual del slice de Redux.
      @param {object} action - Acción que contiene el elemento a agregar en la variable itemSelected.
      @returns {object} Nuevo estado actualizado con el valor de itemSelected actualizado.
    */
    addItemSelected: (state, action) => ({
      ...state,
      itemSelected: action.payload.item,
    }),
  },
})

/**

Acciones que exporta el slice de Redux para ser utilizadas en la aplicación.
@type {object}
*/
export const { addEvent, addItemSelected, addEventEmpresas } =
  confirmAlertSlice.actions

/**

Reducer que exporta el slice de Redux para ser utilizado en la aplicación.
@type {function}
*/
export default confirmAlertSlice.reducer
