/**
 * Restaurar password - user UI
 * @module RestaurarPasswordUserUI
 * @author Fernando Olmos <fol@nowaii.com>
 */
import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
/**
 * Envia props necesarias para dibujar la interfaz del componente RestaurarPasswordUI
 * @function
 * @name RestaurarPasswordUserUI
 * @param {Function} setUser - useState que almacena el usuario que quiere restaurar password
 * @param {Boolean} usuarioDisponible - useState que almacena el usuario que quiere restaurar password
 * @property {String} userEmpleado - useState que almacena el usuario que quiere restaurar password
 * @property {Function} setUserEmpleado - funcion del useState para setear el valor del usuario
 * @property {Boolean} disable - useState que valida la cadena ingresada
 * @property {Function} setDisable - funcion del useState para setear el valor de la verificacion del usuario
 * @return {Component} Llama al componente que contiene la UI de restaurar por Usuario
 */
function RestaurarPasswordUserUI({
  emailEmpleado,
  disable,
  isLoading,
  onSubmit,
  onChange,
}) {
  return (
    <form onSubmit={onSubmit}>
      <div>
        <label htmlFor="inputNombreUsuario" className="fw-semibold">
          Email:
        </label>
        <div className="mt-2">
          <input
            className="input_authentication"
            maxLength="254"
            defaultValue={emailEmpleado}
            placeholder="Ingresa el email que te proporciono la empresa "
            onChange={onChange}
            type="email"
            id="inputNombreUsuario"
            required
          />
        </div>
      </div>
      <div className="mt-5">
        <Button
          className="button_primary w-100"
          type="submit"
          disabled={disable}
        >
          {isLoading ? (
            <FontAwesomeIcon
              className="fa-spin"
              icon={["fas", "fa-circle-notch"]}
            />
          ) : (
            "Solicitar token"
          )}
        </Button>
      </div>
      <div className="mt-3 text-center">
        <Link to="/iniciar-sesion" className="d-inline-block link_normal">
          <span className="d-block not_action">
            ¿No deseas restaurar contraseña?
          </span>
          Iniciar sesión
        </Link>
      </div>
    </form>
  )
}

export default RestaurarPasswordUserUI
