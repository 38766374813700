/**
 * Reducers de Usuario
 * @module ReducersUsuario
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { obtenerDataUsuarioAPI } from "../../utils/AutenticacionAPI"
import {
  obtenerLocalStorage,
  guardarLocalStorage,
} from "../../utils/localStorage"

const initialState = {
  user: {},
  isLoading: true,
}
/**
 * Obtener los datos del usuario a traves de una api
 * @function
 * @name obtenerInformacionUsuario
 * @param {String} - Nombre del modulo / tipo de peticion que realiza
 * @example
 * // En formato de path
 * user/getData
 * @param {Function} - Recibe funcion asincrona 'payloadCreator'
 */
export const obtenerInformacionUsuario = createAsyncThunk(
  "user/getData",
  /**
   * Valida la existencia del token en el localStorage, llama a la funcion obtenerDataUsuarioAPI y guarda en el localstorage la informacion del response
   * @function
   * @name payloadCreator
   * @param {String} name - Nombre de la accion
   * @param {Object} thunkAPI - Objeto que contiene todos los parámetros que normalmente se pasan a una función thunk de Redux
   * @returns {Object} - Retorna el objecto de la informacion del usuario
   */
  async (name, thunkAPI) => {
    try {
      if (obtenerLocalStorage("tokenUsuario") !== null) {
        const userData = await obtenerDataUsuarioAPI(
          obtenerLocalStorage("tokenUsuario")
        )
        guardarLocalStorage("dataUser", userData)
        return userData
      }
    } catch (e) {
      return thunkAPI.rejectWithValue([])
    }
  }
)

export const usuarioSlice = createSlice({
  name: "usuario",
  initialState,
  reducers: {
    addUser: (state, action) => ({
      ...state,
      nombre: action.payload.nombre,
      apellidos: action.payload.apellidos,
      roles: action.payload.roles,
    }),
  },
  /**
   * Evalua el return del payloadCreator para determinar el estado a asignar del state
   * @constant extraReducers
   */
  extraReducers: (builder) => {
    /**
     * En caso de que la peticion del payloadCreator sea exitosa cambiara el state del isLoading
     * @function
     * @name pending
     * @param {Object} state Estado actual del state
     */
    builder.addCase(obtenerInformacionUsuario.pending, (state) => {
      state.isLoading = true
    })
    /**
     * En caso de que la peticion del payloadCreator sea no exitosa cambiara el state del isLoading y cargara la data del user
     * @function
     * @name fulfilled
     * @param {Object} state Estado actual del state
     * @param {Object} action Objecto que trae nuevos valores para el state
     * @property {Object} action.payload - Objecto nuevo que trae los datos del usuario
     */
    builder.addCase(obtenerInformacionUsuario.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload.data
      }
      state.isLoading = false
    })
    /**
     * En caso de que la peticion del payloadCreator sea erronea cambiara el state del isLoading y no cargara datos al state del usuario
     * @function
     * @name rejected
     * @param {Object} state Estado actual del state
     */
    builder.addCase(obtenerInformacionUsuario.rejected, (state, action) => {
      state.isLoading = true
    })
  },
})

export const { addUser } = usuarioSlice.actions
export default usuarioSlice.reducer
