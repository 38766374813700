/**

Componente de React que maneja la notificación automática.
@returns {JSX.Element} Elemento de React que renderiza la notificación.
*/
import { useState } from "react"
import { useSelector } from "react-redux"
import Notificacion from "."
import { getTokenUser, onMessageListener, openNotification } from "../../utils"

export const NotificacionAutomatica = () => {
  /**
  Estado del token encontrado.
  @type {boolean}
  */
  const [isTokenFound, setTokenFound] = useState(false)
  /**
  Estado de la notificación.
  @type {Object}
  */
  const estadoNotificacion = useSelector((state) => state.notification)
  /**

  Estado de la petición individual.
  @type {boolean}
  */
  const [singlePeticion, setSinglePeticion] = useState(false)
  if (!singlePeticion) {
    getTokenUser(setTokenFound)
    setSinglePeticion(true)
  }

  /**

  Escucha las notificaciones entrantes.
  */
  onMessageListener()
    .then((payload) => {
      if (isTokenFound) {
        openNotification(payload.notification.title, payload.notification.body)
      }
    })
    .catch(() => {})

  /**
   * Renderiza la notificación.
   */
  return (
    <Notificacion
      showNotification={estadoNotificacion.shownotification || ""}
      title={estadoNotificacion.title || ""}
      description={estadoNotificacion.description || ""}
    />
  )
}

export default NotificacionAutomatica
