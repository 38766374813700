/* eslint-disable react/prop-types */
/**
 * Componente contenedor padre de autenticacion y restauracion de contraseña
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module LayoutAutenticacion
 */

import { useState, useEffect } from "react"
import LogoPanamotorsBlanco from "../assets/img/grupo_panamotors_logo.png"
import Alertas from "../components/Alertas/Alertas"

/**
 * Recibe como parametro la prop children que contiene los elementos a renderizar
 * @function
 * @name LayoutAutenticacion
 * @param {Object} children - Prop que devuelve el formulario de login y formulario de restauracion de contraseña
 */
function LayoutAutenticacion({ children }) {
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 767px)").matches
  )
  useEffect(() => {
    /**
     * Renderiza el logo y nombre del sistema dependiendo el tamaño de resolucion de pantalla
     * @method
     * @param {ChangeEvent} e - Cambia el estado de la resolucion de pantalla
     * @listens ChangeEvent
     */
    window
      .matchMedia("(max-width: 767px)")
      .addEventListener("change", (e) => setMatches(e.matches))
  }, [])

  return (
    <div className="complete_screen">
      <Alertas />
      <div className="container_authentication">
        <div className="partial_complete_screen">
          <div className="general_card_blur card_authentication">
            {matches && (
              <div className="d-flex justify-content-center mb-3 card_logo_mobile">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      src={LogoPanamotorsBlanco}
                      alt="Logo Grupo Panamotors"
                      className="logo_mobile"
                    />
                  </div>
                  <h2 className="fw-bold mb-0 name_system_mobile">CCP</h2>
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
        {!matches && (
          <div className="partial_complete_screen background_black complete_screen_logo">
            <div className="d-flex align-items-center">
              <div className="me-3">
                <img
                  src={LogoPanamotorsBlanco}
                  alt="Logo Grupo Panamotors"
                  className="logo_desktop"
                />
              </div>
              <h2 className="fw-bold mb-0 name_system_desktop">CCP</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LayoutAutenticacion
