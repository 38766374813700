/**
 * Componente loader
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module Cargando
 */

/**
 * Renderiza el loader animado para visualizar antes de la entrada de datos
 * @function
 * @name Cargando
 */
function Cargando() {
  return (
    <div className="complete_screen content_center_screen container_alert show_container_alert">
      <div className="content_alert">
        <div className="container_shape_alert">
          <div className="shape_alert shape_loader">
            <span className="shape_loader_bg animate_shape_loader" />
          </div>
        </div>
        <div className="mt-5 container_text_alert">
          <span className="fw-semibold">Cargando</span>
        </div>
      </div>
    </div>
  )
}

export default Cargando
