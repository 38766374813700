/**
 * Fetch custom para manejo de alertas de aplicacion segun status de response
 * @module MixinFetch
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import * as qs from "qs"
import { obtenerLocalStorage } from "./localStorage"
import { LogOut } from "./Logout"
import { openModal, closeModal, errorFetch } from "./ControladorAlertas"
/**
 * Fetching a endpoint
 * @async
 * @function
 * @name customFetch
 * @param {String} url - Ruta a la que hay que realzar fetch (https://www.example.com/api/v4/user)
 * @param {Object} data - Este objeto debe de contener los datos a enviar dentro del body del fetch, por defecto es un objeto vacio {}
 * @param {String} method - Metodo del fetch a realizar por defecto es GET
 * @param {Boolean} actionUser - Esta bandera indica si la peticion a realizar es hecha por un usuario para desencadenar un alerta, por defecto es false
 * @param {Boolean} files - Esta bandera indica si la peticion a realizar contiene carga de archivos, por defecto es false
 * @returns {object}
 */
export const customFetch = async (
  url,
  data = {},
  method = "GET",
  actionUser = false,
  files = false
) => {
  let URL = url
  const reqConfig = {
    method,
    headers: {},
  }
  if (obtenerLocalStorage("tokenUsuario") !== null) {
    reqConfig.headers.Authorization = `Token ${obtenerLocalStorage(
      "tokenUsuario"
    )}`
  }
  if (Object.keys(data).length || data instanceof FormData) {
    if (files) {
      reqConfig.body = data
      reqConfig.headers.platform = "web"
    } else {
      reqConfig.headers["Content-Type"] = "application/json"
      reqConfig.headers.platform = "web"
      if (method === "GET") {
        URL += `?${qs.stringify(data)}`
      } else {
        reqConfig.body = JSON.stringify(data)
      }
    }
  }
  try {
    const request = await fetch(URL, reqConfig)
    const response = await request.json()
    switch (request.status) {
      case 200: {
        if (actionUser) {
          if (response.error) {
            openModal(response.mensaje, "Error")
            closeModal()
          } else {
            openModal(response.mensaje, "Success")
            closeModal()
          }
        }
        return response
      }
      case 401: {
        openModal("No estas autentificado")
        closeModal()
        setTimeout(() => {
          LogOut()
        }, 2200)
        break
      }
      case 403: {
        if (actionUser) {
          openModal("No tienes permiso para realizar esta accion", "Warning")
          closeModal()
        }
        return response
      }
      case 404: {
        if (actionUser) {
          openModal("No existe el elemento buscado", "Error")
          closeModal()
        }
        return response
      }
      case 500: {
        openModal("Error de aplicacion", "Error")
        closeModal()
        return response
      }
      case 503: {
        const time = 1200
        openModal("Módulo en mantenimiento, intenta mas tarde", "Warning")
        closeModal()
        setTimeout(() => {
          window.location.replace(
            `${window.location.protocol}//${window.location.host
            }/#/mantenimiento/${btoa(parseInt(time))}`
          )
        }, 500)
        return response
      }
      default: {
        openModal("Error", "Error")
        closeModal()
        return response
      }
    }
  } catch (e) {
    errorFetch()
  }
}
