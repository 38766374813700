import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutAutenticacion from "../../containers/LayoutAutenticacion"
import InputOTP from "../InputOTP"
import { addToken } from "../../features/RestaurarPassword"
import { validarTokenRestaurarPassword } from "../../utils/RestaurarPassword"
import {
  guardarLocalStorage,
  obtenerLocalStorage,
} from "../../utils/localStorage"

function ValidarToken() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [token, setToken] = useState(null)
  const idUsuario = obtenerLocalStorage("RestaurarPaso1")
    ? obtenerLocalStorage("RestaurarPaso1").idUsuario
    : null
  const [disable, setDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // const handleVerificarToken = () => {
  //     if(token!==null && token !== '' && token.length===4){
  //         const responseValidarToken = {
  //             success: true
  //         }
  //         if(responseValidarToken.success){
  //             // dispatch(addToken({token: token}));
  //             // navigate('/restaurar-password/nuevo-password')
  //         }else{
  //             // console.log('Validacion de error')
  //         }
  //     }
  // }
  const dataToken = null
  const handleVerificarToken = async () => {
    setIsLoading(true)
    setDisable(true)
    const respuesta = await validarTokenRestaurarPassword(idUsuario, token)
    if (!respuesta.error) {
      guardarLocalStorage("RestaurarPaso2", {
        token: respuesta.data.token_validacion,
        user: respuesta.data.usuario.username,
      })
      dispatch(addToken({ token: dataToken }))
      navigate("/restaurar-password/nuevo-password")
    }
    setDisable(false)
    setIsLoading(false)
  }

  const resolverConfirmarTokenEnter = (event) => {
    if (event.key === "Enter") {
      if (token > 999 && !disable) {
        handleVerificarToken()
      }
    }
  }
  useEffect(() => {
    if (idUsuario === null) {
      navigate("/")
    }
  }, [idUsuario])

  return (
    <LayoutAutenticacion>
      <h1 className="fw-bold">Restaurar contraseña</h1>
      <p>
        Por favor revise su correo electrónico, enviamos un token de seguridad
        le solicitamos que lo ingrese a continuación.
      </p>

      <div className="mt-3">
        <div className="d-flex flex-column align-items-center">
          <label htmlFor="inputNombreUsuario" className="fw-semibold">
            Token
          </label>
          <div className="mt-2">
            <InputOTP
              autoFocus
              isNumberInput
              length={4}
              className="container_input_code"
              inputClassName="input_code"
              onChangeOTP={(optTOken) => setToken(optTOken)}
              resolverConfirmarTokenEnter={resolverConfirmarTokenEnter}
            />
          </div>
        </div>
        <div className="mt-5">
          <Button
            className="button_primary w-100"
            type="button"
            disabled={!(token > 999 && !disable)}
            onClick={() => handleVerificarToken()}
          >
            {isLoading ? (
              <FontAwesomeIcon
                className="fa-spin"
                icon={["fas", "fa-circle-notch"]}
              />
            ) : (
              "Validar token"
            )}
          </Button>
        </div>
        <div className="mt-3 text-center">
          <Link to="/iniciar-sesion" className="d-inline-block link_normal">
            <span className="d-block not_action">
              ¿No deseas restaurar contraseña?
            </span>
            Iniciar sesión
          </Link>
        </div>
      </div>
    </LayoutAutenticacion>
  )
}

export default ValidarToken
