/**

Componente que representa la vista para la gestión de tokens.
@function
@returns {JSX.Element} Elemento JSX que representa la vista de gestión de tokens.
*/

import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  handleGetTokens,
  handleDeleteTokens,
  handleGetBrowserIcon,
  validateSesion,
} from "./utils"
import { addEvent } from "../../features/Confirm"
import AlertaGeneral from "../Alertas/AlertaGeneral"
import Alertas from "../Alertas/Alertas"

export function Token() {
  const tokens = useSelector((state) => state.tokens)
  const [isLoading, setIsLoading] = useState(true)
  const [itemSelected, setItemSelected] = useState({})
  const dispatch = useDispatch()

  /**

  Manejador para eliminar un token de un navegador.
  @function
  @param {object} item - Objeto que representa el token.
  @returns {void}
  */
  const handleDeleteBrowser = (item) => {
    handleDeleteTokens({ id: item }, setIsLoading)
  }

  /**

  Manejador para confirmar la eliminación de un token de un navegador.
  @function
  @param {object} item - Objeto que representa el token.
  @returns {object} - El objeto item recibido como parámetro.
  */
  const handleConfirmDeleteBrowser = (item) => {
    dispatch(addEvent({ status: true }))
    return item
  }

  /**

  Efecto que se encarga de obtener los tokens al cargar la vista.
  @function
  @returns {void}
  */
  useEffect(() => {
    handleGetTokens(setIsLoading)
  }, [])
  return (
    <>
      <AlertaGeneral
        confirmarAccion={handleDeleteBrowser}
        item={itemSelected}
        mensajeAlerta="¿Estas seguro que deseas dejar de recibir notificaciones en esta sesion?"
      />
      <Alertas />
      <div className="complete_screen content_center_screen">
        <div className="general_card_blur card_change_password">
          <Link to="/panel" className="fw-semibold link_normal">
            <FontAwesomeIcon icon={faArrowLeft} />
            Ir a módulos
          </Link>
          <h3 className="fw-bold">Panel de notificaciones</h3>
          <p>
            Gestiona los diferentes <b>navegadores</b> donde deseas recibir
            notificaciones.
          </p>
          {!isLoading && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" colSpan={3}>
                    Navegador
                  </th>
                </tr>
              </thead>
              <tbody>
                {tokens?.data?.length !== 0 ? (
                  <>
                    {tokens &&
                      tokens?.data &&
                      tokens?.data.map((token) => (
                        <tr
                          key={`tr-${token.id}`}
                          style={{ verticalAlign: "middle" }}
                        >
                          <td>
                            <img
                              src={handleGetBrowserIcon(token.browser_data)}
                              alt=""
                              style={{ width: "20px" }}
                            />
                          </td>
                          <td>
                            {`${token.browser_data} ${validateSesion(
                              token.token
                            )}`}
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                handleConfirmDeleteBrowser(token)
                                setItemSelected(token)
                              }}
                              className="button_icon"
                            >
                              <FontAwesomeIcon icon="fa-solid fa-trash" />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={3}>
                      Aun no se han activado las notificaciones
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  )
}

export default Token
