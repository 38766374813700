/**

@function handleGetTokens
@async
@param {function} setIsLoading - Función que establece el estado de carga de la página.
@returns {Promise} Promesa que devuelve los tokens obtenidos de la API.
@description Obtiene los tokens de la API y actualiza el estado de la aplicación.
*/

import { store } from "../../../app/Store"
import { addToken } from "../../../features/Token"
import { deleteTokens, getTokens } from "../../../utils/Token"
import { obtenerLocalStorage } from "../../../utils/localStorage"
import {
  chrome,
  edge,
  explorer,
  firefox,
  opera,
  safari,
  browser,
} from "../../../assets/icons"

/**

@function handleDeleteTokens
@async
@param {object} params - Objeto con los parámetros necesarios para eliminar los tokens.
@param {function} setIsLoading - Función que establece el estado de carga de la página.
@returns {Promise} Promesa que devuelve la respuesta de la API al eliminar los tokens.
@description Elimina los tokens de la API y actualiza el estado de la aplicación.
*/
export const handleGetTokens = async (setIsLoading) => {
  setIsLoading(true)
  const response = await getTokens()
  if (Object.entries(response.data).length > 0) {
    setData(response)
  } else {
    setData({ data: [] })
  }
  setIsLoading(false)
}

export const handleDeleteTokens = async (params, setIsLoading) => {
  const response = await deleteTokens(params)
  if (!response.error) {
    setIsLoading(false)
  }
  handleGetTokens(setIsLoading)
}

/**

@function setData
@param {object} data - Objeto con los datos a actualizar en el estado de la aplicación.
@description Actualiza los tokens en el estado de la aplicación mediante la acción addToken del Store.
*/
const setData = ({ data }) => {
  store.dispatch(addToken({ data }))
}

/**

@function handleGetBrowserIcon
@param {string} browserData - Cadena de texto con el nombre del navegador.
@returns {object} Icono del navegador.
@description Obtiene el icono del navegador correspondiente al nombre pasado como parámetro.
*/
export const handleGetBrowserIcon = (browserData) => {
  if (browserData.includes("Chrome")) {
    return chrome
  }
  if (browserData.includes("Safari")) {
    return safari
  }
  if (browserData.includes("Opera")) {
    return opera
  }
  if (browserData.includes("Mozilla Firefox")) {
    return firefox
  }
  if (browserData.includes("Microsoft Internet Explorer")) {
    return explorer
  }
  if (browserData.includes("Microsoft Edge")) {
    return edge
  }
  return browser
}

/**

@function validateSesion
@param {string} token - Cadena de texto con el token a validar.
@returns {string} Cadena de texto con el mensaje correspondiente a la validación del token.
@description Valida si el token pasado como parámetro corresponde a la sesión actual del usuario.
*/
export const validateSesion = (token) => {
  let response = ""
  if (obtenerLocalStorage("notification") === token) {
    response = "(Sesion actual)"
  }
  return response
}
