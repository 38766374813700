/**
 * Componente para el header del panel
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module Header
 */

import { useState, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faKey, faBell } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import CerrarSesion from "../CerrarSesion"
import LogoPanamotorsRojo from "../../assets/img/logo_panamotors_glifo_rojo_2.png"
import Skeleton from "../Skeleton/Skeleton"
import BoundaryErrorUser from "../Errors/BoundaryErrorUser"
import { NotificacionAutomatica } from ".."

/**
 * Componente donde se renderiza el nombre de usuario y las acciones que puede realizar
 * @function
 * @name Header
 */
function Header() {
  const usuario = useSelector((state) => state.usuario)
  const [mostrarDropdown, setMostrarDropdown] = useState(false)
  const contenidoPerfil = useRef(null)

  /**
   * Muestra y oculta dropdown de acciones del usuario
   * @function
   * @name activarDropdownPerfil
   */
  const activarDropdownPerfil = () => {
    setMostrarDropdown(!mostrarDropdown)
  }

  /**
   * Oculta el dropdown al hacer click en todo el documento
   * @function
   * @name useOcultarDropdown
   * @param {Object} ref - Recibe el objeto usado como referencia
   */
  const useOcultarDropdown = (ref) => {
    useEffect(() => {
      /**
       * Oculta el dropdown al hacer click en todo el documento
       * @function
       * @name clickFuera
       * @param {Object} event - Recibe el evento del mousedown haciendo un cambio de estado para ocultar el dropdown
       */
      const clickFuera = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setMostrarDropdown(false)
        }
      }
      document.addEventListener("mousedown", clickFuera)
      return () => {
        document.removeEventListener("mousedown", clickFuera)
      }
    }, [ref])
  }

  useOcultarDropdown(contenidoPerfil)

  function IconoUsuario({ usuario }) {
    return (
      <div className="me-2 content_perfil_name">
        {usuario.isLoading ? (
          <Skeleton
            tipo="Bar nombre perfil"
            numCards={1}
            widthCard={180}
            heightCard={30}
          />
        ) : (
          <h2 className="mb-0 perfil_name">
            {usuario.user.usuario.first_name} {usuario.user.usuario.last_name}
          </h2>
        )}
      </div>
    )
  }

  return (
    <header className="header_panel">
      <NotificacionAutomatica />
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-center">
          <div className="me-2">
            <img
              src={LogoPanamotorsRojo}
              alt="Logo Grupo Panamotors"
              className="logo_panel"
            />
          </div>
          <h1 className="mb-0 fw-bold name_panel">CCP</h1>
        </div>
        <div
          ref={contenidoPerfil}
          onClick={activarDropdownPerfil}
          className="d-flex align-items-center content_perfil"
        >
          <BoundaryErrorUser>
            <IconoUsuario usuario={usuario} />
          </BoundaryErrorUser>
          <div className="content_perfil_avatar">
            <button className="perfil_avatar">
              <FontAwesomeIcon icon={faUser} />
            </button>
          </div>
          <div
            className={`dropdown_menu dropdown_menu_right ${
              mostrarDropdown ? "show_dropdown_menu" : ""
            }`}
          >
            <ul className="ps-0 mb-0">
              <Link to="/cambiar-contrasena">
                <FontAwesomeIcon icon={faKey} />
                Cambiar contraseña
              </Link>
              <Link to="/gestionar-notificaciones">
                <FontAwesomeIcon icon={faBell} />
                Gestionar notificaciones
              </Link>
              <CerrarSesion />
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
