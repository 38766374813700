/**
 * Formulario para el inicio de sesion
 * @author Jesus Gonzalez <jgp@nowaii.com>
 * @module IniciarSesion
 */
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { addSesion, cargandoEvento } from "../features/auth/autenticacionSlice"
import { addMessage, addEvent } from "../features/actions/alertsSlice"
import { IniciarSesion } from "../utils/AutenticacionAPI"
import { guardarLocalStorage, limpiarLocalStorage } from "../utils/localStorage"
import LayoutAutenticacion from "../containers/LayoutAutenticacion"
import Alertas from "./Alertas/Alertas"

function Login() {
  const [nombreUsuario, setNombreUsuario] = useState("")
  const [contrasenia, setContrasenia] = useState("")
  const [mostrarContrasenia, setMostrarContrasenia] = useState(false)
  const dispatch = useDispatch()
  /**
   * Realiza el inicio de sesion al presionar la tecla enter
   * @function
   * @name resolverIniciarSesionEnter
   * @param {Object} event - Este objeto posee el valor de la tecla presionada dentro del input
   */
  const resolverIniciarSesionEnter = (event) => {
    if (event.key === "Enter") {
      if (nombreUsuario !== "" && contrasenia !== "") {
        resolverIniciarSesion()
      }
    }
  }
  /**
   * Validacion de response para iniciar sesion
   * @async
   * @function
   * @name resolverIniciarSesion
   */
  const resolverIniciarSesion = async () => {
    dispatch(cargandoEvento({ cargando: true }))
    limpiarLocalStorage()
    try {
      const resInicioSesion = await IniciarSesion(nombreUsuario, contrasenia)
      if (resInicioSesion.token) {
        guardarLocalStorage("tokenUsuario", resInicioSesion.token)
        dispatch(addMessage({ type: "Success", message: "Acceso concedido" }))
        dispatch(addEvent({ show: true }))
        setTimeout(() => {
          dispatch(addEvent({ show: false }))
        }, 2500)
        dispatch(
          addSesion({
            cargando: false,
            autenticado: true,
            token: resInicioSesion.token,
          })
        )
      } else {
        setNombreUsuario("")
        setContrasenia("")
        dispatch(cargandoEvento({ cargando: false }))
        dispatch(
          addMessage({
            type: "Error",
            message:
              "No se puede iniciar sesión con las credenciales proporcionadas",
          })
        )
        dispatch(addEvent({ show: true }))
        setTimeout(() => {
          dispatch(addEvent({ show: false }))
        }, 2500)
      }
    } catch (error) {
      setNombreUsuario("")
      setContrasenia("")
      dispatch(cargandoEvento({ cargando: false }))
      dispatch(
        addMessage({ type: "Error", message: "Error al iniciar sesión" })
      )
      dispatch(addEvent({ show: true }))
      setTimeout(() => {
        dispatch(addEvent({ show: false }))
      }, 2500)
    }
  }
  return (
    <>
      <Alertas />
      <LayoutAutenticacion>
        <h1 className="fw-bold">Iniciar sesión</h1>
        <p>
          Bienvenido de nuevo! Ingresa los datos correctamente para acceder al
          sistema.
        </p>
        <div>
          <div>
            <label htmlFor="inputNombreUsuario" className="fw-semibold">
              Nombre de usuario
            </label>
            <div className="mt-2">
              <input
                className="input_authentication"
                type="text"
                value={nombreUsuario}
                onChange={(texto) => setNombreUsuario(texto.target.value)}
                id="inputNombreUsuario"
                placeholder="Ingresa tu nombre de usuario"
                onKeyUp={resolverIniciarSesionEnter}
              />
            </div>
          </div>
          <div className="mt-2">
            <label htmlFor="inputContrasenia" className="fw-semibold">
              Contraseña
            </label>
            <div className="mt-2 position-relative">
              <input
                className="input_authentication"
                type={mostrarContrasenia ? "text" : "password"}
                value={contrasenia}
                onChange={(texto) => setContrasenia(texto.target.value)}
                id="inputContrasenia"
                placeholder="Ingresa tu contraseña"
                onKeyUp={resolverIniciarSesionEnter}
              />
              <button
                className="button_icon button_icon_password"
                onClick={() => setMostrarContrasenia(!mostrarContrasenia)}
              >
                <FontAwesomeIcon
                  icon={mostrarContrasenia ? faEye : faEyeSlash}
                  style={{ color: mostrarContrasenia ? "#E33333" : "#585858" }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <button
            disabled={!!(nombreUsuario === "" || contrasenia === "")}
            className="button_primary w-100"
            onClick={() => resolverIniciarSesion()}
          >
            Ingresar
          </button>
        </div>
        <div className="mt-3 text-center">
          <Link to="/restaurar" className="d-inline-block link_normal">
            <span className="d-block not_action">
              ¿Tienes problemas con tu acceso?
            </span>
            Restaurar contraseña
          </Link>
        </div>
      </LayoutAutenticacion>
    </>
  )
}

export default Login
