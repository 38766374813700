/**
 * Componente contenedor padre de modulos
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module ModulosLista
 */

/**
 * Recibe como parametro la prop children donde se integran los modulos
 * @function
 * @name ModulosLista
 * @param {Object} children - Prop que devuelve cada uno de los elementos que comprende el componente módulo
 */
function ModulosLista({ children }) {
  return (
    <section className="container_panel">
      <div className="container_general_card">{children}</div>
    </section>
  )
}

export default ModulosLista
