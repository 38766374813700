/**

Componente AlertaGeneral
Muestra una alerta con opciones de confirmación y cancelación.
@param {Function} confirmarAccion - Función a ejecutar al confirmar la acción.
@param {Object} item - Objeto a mostrar en la alerta.
@param {String} mensajeAlerta - Mensaje a mostrar en la alerta.
@returns {JSX.Element} - Retorna un componente JSX que representa una alerta modal.
*/
import { useSelector, useDispatch } from "react-redux"
import { addEvent } from "../../features/Confirm"

/**

Maneja la acción al presionar el botón de confirmar.
Ejecuta la función de confirmar acción y cierra la alerta.
*/
const AlertaGeneral = ({ confirmarAccion, item, mensajeAlerta }) => {
  const dispatch = useDispatch()
  const confirmarAlerta = useSelector((state) => state.confirm)
  const handleAccion = () => {
    confirmarAccion(item.id)
    dispatch(addEvent({ status: false }))
  }

  return (
    <div
      className={`container_confirmation_alert ${
        confirmarAlerta.showModalAlert
          ? "show_container_confirmation_alert"
          : ""
      }`}
    >
      <div
        className={`content_confirmation_alert ${
          confirmarAlerta.showModalAlert
            ? "show_content_confirmation_alert"
            : ""
        }`}
      >
        <span className="d-block text-center confirmation_alert_text">
          {mensajeAlerta}
        </span>
        <div className="confirmation_alert_actions">
          <button className="button_secondary" onClick={handleAccion}>
            Si, estoy seguro
          </button>
          <button
            className="button_primary"
            onClick={() => dispatch(addEvent({ status: false }))}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  )
}

export default AlertaGeneral
