/**

Clase que implementa un boundary para capturar errores al consultar los datos de usuario.
*/
import React from "react"

class BoundaryErrorUser extends React.Component {
  /**

  Constructor de la clase.
  @param {Object} props - Propiedades del componente.
  */
  constructor(props) {
    super(props)
    this.state = { tieneError: false, mensajeError: "" }
  }

  /**

  Método estático que se ejecuta cuando ocurre un error en el componente.
  @param {Error} error - El error que ocurrió.
  @returns {Object} - Un objeto con el estado actualizado.
  */
  static getDerivedStateFromError(error) {
    // Método 1
    return { tieneError: true, mensajeError: error.message }
  }

  /**

  Método que se ejecuta cuando ocurre un error en el componente.
  @param {Error} error - El error que ocurrió.
  @returns {Error} - El error que ocurrió.
  */
  componentDidCatch(error) {
    // Método 2
    // Ambos sirven por igual
    return error
  }

  /**

  Renderiza el componente.
  @returns {JSX.Element} - El componente renderizado.
  */
  render() {
    if (this.state.tieneError) {
      return (
        <div className="me-2 content_perfil_name">
          <h2 className="mb-0 perfil_name">
            Ha ocurrido un error al consultar tus datos
          </h2>
        </div>
      )
    }

    return this.props.children
  }
}

export default BoundaryErrorUser
