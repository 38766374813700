/**

Define un objeto con el estado inicial del slice de notificaciones.
@typedef {Object} NotificationState
@property {boolean} shownotification Indica si se debe mostrar la notificación.
@property {string} title El título de la notificación.
@property {string} description La descripción de la notificación.
*/

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  shownotification: false,
  title: "",
  description: "",
}

/**

Define un slice de Redux para manejar el estado de las notificaciones.
*/
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    /**

      Actualiza el estado de shownotification en función del valor del payload.
      @param {NotificationState} state El estado actual del slice.
      @param {Object} action La acción a ejecutar.
      @param {boolean} action.payload El valor a asignar a shownotification.
    */
    addEventNotification: (state, action) => ({
      ...state,
      shownotification: action.payload.show,
    }),

    /**

      Actualiza el estado de title en función del valor del payload.
      @param {NotificationState} state El estado actual del slice.
      @param {Object} action La acción a ejecutar.
      @param {string} action.payload El valor a asignar a title.
    */
    addTitle: (state, action) => ({
      ...state,
      title: action.payload.title,
    }),

    /**

      Actualiza el estado de description en función del valor del payload.
      @param {NotificationState} state El estado actual del slice.
      @param {Object} action La acción a ejecutar.
      @param {string} action.payload El valor a asignar a description.
    */
    addDescription: (state, action) => ({
      ...state,
      description: action.payload.description,
    }),
  },
})
export const { addEventNotification, addTitle, addDescription } =
  notificationSlice.actions
export default notificationSlice.reducer
