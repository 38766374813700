/**
 * Componente para modulos no encontrados
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module ModuloNoEncontrado
 */

import ImagenModuloNoEncontrado from "../../../assets/img/no_encontrados_modulos.png"

/**
 * Renderiza la imagen y texto en caso de que no se haya encontrado el módulo en la búsqueda
 * @function
 * @name ModuloNoEncontrado
 */
function ModuloNoEncontrado() {
  return (
    <div className="container_module_not_found">
      <div className="d-flex justify-content-center">
        <img
          src={ImagenModuloNoEncontrado}
          alt="Modulo No Encontrado"
          className="image_module_not_found"
        />
      </div>
      <div className="mt-3">
        <p className="text_module_not_found mb-0">
          No se ha encontrado ningún módulo
        </p>
      </div>
    </div>
  )
}

export default ModuloNoEncontrado
