/**

Define el estado inicial y las acciones para la gestión de tokens
@module Tokens
*/

import { createSlice } from "@reduxjs/toolkit"

/**

Estado inicial de la gestión de tokens
@typedef {Object} TokensState
@property {Array} data - Lista de tokens
*/
const initialState = {
  data: [],
}

/**

Slice para la gestión de tokens
@type {Slice}
*/
export const Tokens = createSlice({
  name: "Tokens",
  initialState,
  reducers: {
    /**
      Añade una lista de tokens al estado
      @function
      @param {TokensState} state - Estado actual
      @param {Object} action - Acción que contiene la lista de tokens
      @param {Array} action.payload.data - Lista de tokens
    */
    addToken: (state, action) => ({
      ...state,
      data: action.payload.data,
    }),
  },
})

export const { addToken } = Tokens.actions
export default Tokens.reducer
