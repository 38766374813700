/**
 * Restaurar password UI
 * @module RestaurarPasswordCurp
 * @author Fernando Olmos <fol@nowaii.com>
 */

import { useState } from "react"
import RestaurarPasswordUI from "./RestaurarPasswordCurpUI"

/**
 * Renderiza UI para determinar como sera el reseteo de Password
 * @function
 * @name setCurp
 */
const setCurp = (event) =>
  // Falta incluir peticion API para reseteo de password
  event

/**
 * Verifica si la CURP ingresada es valida
 * @function
 * @name curpValida
 * @param {String} curp - Curp ingresada por el usuario
 * @return {Boolean} True si la CURP ingresada es valida False si no cumple con la verificacion
 */
const curpValida = (curp) => {
  const regexCurp =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
  const validado = curp.match(regexCurp)

  if (!validado) return false

  const digitoVerificador = (curp17) => {
    const diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ"
    let lngSuma = 0.0
    let lngDigito = 0.0
    for (let i = 0; i < 17; i++) {
      lngSuma += diccionario.indexOf(curp17.charAt(i)) * (18 - i)
    }
    lngDigito = 10 - (lngSuma % 10)
    if (lngDigito === 10) return 0
    return lngDigito
  }

  if (validado[2] !== digitoVerificador(validado[1])) return false

  return true
}
/**
 * Pasar propiedades al componente RestaurarPasswordUI
 * @function
 * @name RestaurarPasswordCurp
 * @property {Boolean} usuarioDisponible - Valor useState que envia bandera de verificacion del usuario
 * @property {Function} setUsuarioDisponible - Funcion del useState que asigna la bandera de verificacion del usuario
 * @return {Component} Llama al componente que contiene la UI de restaurar por CURP
 */
function RestaurarPasswordCurp() {
  const [usuarioDisponible, setUsuarioDisponible] = useState(false)
  setUsuarioDisponible(false)
  return (
    <RestaurarPasswordUI
      setCurp={setCurp}
      curpValida={curpValida}
      usuarioDisponible={usuarioDisponible}
    />
  )
}

export default RestaurarPasswordCurp
