/**

Componente React para la recuperación y cambio de contraseña.
@param {Object} props - Propiedades del componente.
@param {boolean} props.restaurar - Indica si se está en el proceso de restauración de contraseña.
@param {ReactNode} props.children - Componente hijo a renderizar dentro del layout.
@returns {ReactNode} Componente React para recuperación y cambio de contraseña.
*/

import LayoutAutenticacion from "../../containers/LayoutAutenticacion"
import CambiarPassword from "./CambiarPassword"

function RecuperarCambioPassword({ restaurar, children }) {
  return (
    <LayoutAutenticacion>
      <CambiarPassword restaurar={restaurar} />
    </LayoutAutenticacion>
  )
}

export default RecuperarCambioPassword
