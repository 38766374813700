/**
Componente que muestra un mensaje de error y una imagen representativa cuando el usuario no tiene permisos para acceder a una sección.
@component
*/

import NotFound from "../../../assets/img/NotFound.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**

Función que devuelve el componente Error403.
@returns {ReactNode} - El contenido que se va a renderizar.
*/
export const Error404 = () => {
  /*
  Manejador del evento click del enlace de redirección al panel de módulos.
  Redirige al usuario al panel de módulos.
  @function
  */
  return (
    <div className="container_module_not_found">
      <div className="d-flex justify-content-center">
        <img
          src={NotFound}
          alt="No se ha encontrado"
          className="img_error_303"
        />
      </div>
      <div className="mt-3">
        <p className="text_module_not_found mb-0">
          No se encontro el elemento solicitado
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <a
        href="/"
          className=" text_module_not_found d-inline-block link_normal"
        >
          <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
          Volver al inicio
        </a>
      </div>
    </div>
  )
}

export default Error404
