import React from "react"
import ReactDOM from "react-dom/client"
import { HashRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./app/Store"
import "./index.css"
import App from "./App"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

const dsnSentry = process.env.REACT_APP_SENTRY_DSN
const enviroment = process.env.REACT_APP_ENVIROMENT

if (enviroment === "PRODUCTION") {
  Sentry.init({
    dsn: dsnSentry,
    integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
    tracesSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  </React.StrictMode>
)
