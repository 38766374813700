/**

Hook personalizado que guarda el valor anterior de una variable.
@param {any} value - Valor actual que se desea guardar como previo.
@returns {Object} - Objeto con la referencia al valor previo.
*/

import { useRef, useEffect } from "react"

const usePrevius = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])
}

export default usePrevius
