/**
 * Componente que contiene boton para cerrar sesion
 * @author Jesus Gonzalez <jgp@nowaii.com>
 * @module CerrarSesonUI
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOut } from "@fortawesome/free-solid-svg-icons"
import { LogOut } from "../utils/Logout"

function CerrarSesion() {
  /**
   * Llama a funcion para cerrar sesion
   * @async
   * @function
   * @name resolverCerrarSesion
   */
  const resolverCerrarSesion = async () => {
    LogOut()
  }
  return (
    <li onClick={() => resolverCerrarSesion()}>
      <FontAwesomeIcon icon={faSignOut} />
      Cerrar sesion
    </li>
  )
}

export default CerrarSesion
