/**
 * Definicion de rutas publicas y privadas de la aplicacion
 * @module RutasAplicacion
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import { Routes, Route, Navigate } from "react-router-dom"
import RutasPublicas from "./RutasPublicas"
import RutasAutenticacion from "./RutasAutenticacion"
import Login from "../components/Login"
import PanelMenu from "../PanelMenu"
import RestaurarPassword from "../components/RestaurarPassword/RestaurarPassword"
import CambiarPassword from "../components/CambiarPassword/CambiarPassword"
import ValidarToken from "../components/RestaurarPassword/ValidarToken"
import RecuperarCambioPassword from "../components/CambiarPassword/RecuperarCambioPassword"
import { Error404, Mantenimiento, Token } from "../components"
/**
 * Renderiza un arbol de direcciones de rutas de la aplicacion
 * @function
 * @name RutasAplicacion
 * @returns {Object}
 */
function RutasAplicacion() {
  return (
    <Routes>
      <Route path="/" element={<RutasPublicas />}>
        <Route path="/" element={<Navigate replace to="/iniciar-sesion" />} />
        <Route path="/iniciar-sesion" element={<Login />} />
        <Route path="/restaurar" element={<RestaurarPassword />} />
        <Route
          path="/restaurar-password/verificar-token"
          element={<ValidarToken />}
        />
        <Route
          path="/restaurar-password/nuevo-password"
          element={<RecuperarCambioPassword restaurar />}
        />
      </Route>
      <Route path="/" element={<RutasAutenticacion />}>
        <Route path="/" element={<Navigate replace to="/panel" />} />
        <Route path="/panel" element={<PanelMenu />} />
        <Route path="/cambiar-contrasena" element={<CambiarPassword />} />
        <Route path="/gestionar-notificaciones" element={<Token />} />
      </Route>
      <Route path='/error-404' element={<Error404 />} />
      <Route path='/mantenimiento/:tiempoMantenimiento' element={<Mantenimiento />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default RutasAplicacion
