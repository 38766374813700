/**
 * Funciones que realizan fetch a endpoints
 * @module AutenticacionAPI
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import { customFetch } from "./mixinFetch"

export const VerificarSesion = async (tokenUsuario) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}seattings/verify-conection`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${tokenUsuario}`,
        "Content-Type": "application/json",
      },
    }
  )
  return await res.json()
}
/**
 * Fetch para obtener datos del usuario
 * @returns {Object}}
 */
export const obtenerDataUsuarioAPI = async () =>
  await customFetch(`${process.env.REACT_APP_API_URL}cuenta/me`)
/**
 * Fetch para iniciar sesion
 * @async
 * @function
 * @param {String} correo - Direccion de correo electronico
 * @param {String} contraseña - Contraseña
 * @returns {Object}
 */
export const IniciarSesion = async (correo, contraseña) =>
  await customFetch(
    `${process.env.REACT_APP_API_URL}auth/token`,
    { username: correo, password: contraseña },
    "POST"
  )
/**
 * Fetch para cerrar sesion
 * @async
 * @function CerrarSesionAPI
 * @returns {Object}
 */
export const CerrarSesionAPI = async () => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}account/logout`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + "tokenUsuario",
      "Content-Type": "application/json",
    },
  })
  return await res.json()
}
