/**
Componente que muestra un mensaje de error y una imagen representativa cuando el usuario no tiene permisos para acceder a una sección.
@component
*/

import { useEffect, useRef, useState } from "react"
import MantenimientoWeb from "../../../assets/img/MantenimientoWeb.png"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useParams } from "react-router-dom"
import { limpiarSesionStorage } from "../../../utils/localStorage"

/**
Función que devuelve el componente Error403.
@returns {ReactNode} - El contenido que se va a renderizar.
*/
export const Mantenimiento = () => {
    const { tiempoMantenimiento } = useParams()
    /*
    Manejador del evento click del enlace de redirección al panel de módulos.
    Redirige al usuario al panel de módulos.
    @function
    */
    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null)
    // The state for our timer
    const [timer, setTimer] = useState("00:00:00")
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date())
        const seconds = Math.floor((total / 1000) % 60)
        const minutes = Math.floor((total / 1000 / 60) % 60)
        const hours = Math.floor((total / 1000 / 60 / 60) % 24)
        return {
            total, hours, minutes, seconds
        }
    }
    const startTimer = (e) => {
        const { total, hours, minutes, seconds } = getTimeRemaining(e)
        if (total >= 0) {
            setTimer((hours > 9 ? hours : "0" + hours) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds))
        }
    }
    const clearTimer = (e) => {
        setTimer("--:--:--")
        if (Ref.current) clearInterval(Ref.current)
        const id = setInterval(() => {
            startTimer(e)
        }, 1000)
        Ref.current = id
    }

    const getDeadTime = (time) => {
        const deadline = new Date() // This is where you need to adjust if you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + time)
        return deadline
    }
    useEffect(() => {
        setTimeout(() => {
            limpiarSesionStorage()
        }, 1000)
        clearTimer(getDeadTime(parseInt(atob(tiempoMantenimiento.trim()))))
    }, [])
    return (
        <div className="complete_screen content_center_screen pe-5 ps-5">
            <div className="general_card_blur card_change_password">
                <Link to="/panel" className="fw-semibold link_normal">
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Volver
                </Link>
                <div className="mt-3">
                    <p className="text_module_not_found mb-0">
                        Módulo en mantenimiento
                    </p>
                    <p className="mt-2">Podrás acceder nuevamente en cuanto termine la cuenta regresiva. Agradecemos tu paciencia.</p>
                </div>
                <div className="d-flex justify-content-center pe-5 ps-5">
                    <img
                        src={MantenimientoWeb}
                        alt="Modulo en mantenimiento"
                        style={{ width: "100%" }}
                    />
                </div>
                <div >
                    <h6 className="text-center">Este módulo estara nuevamente en linea en:</h6>
                    <p className="text_module_not_found mb-0">
                        {timer}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Mantenimiento
