/**
 * Restaurar password UI
 * @module RestaurarPasswordUI
 * @author Fernando Olmos <fol@nowaii.com>
 */

import RestaurarPasswordCurp from "./RestaurarPasswordCurp/RestaurarPasswordCurp"
import RestaurarPasswordUser from "./RestaurarPasswordUser/RestaurarPasswordUser"
import LayoutAutenticacion from "../../containers/LayoutAutenticacion"

/**
 * Renderiza UI para determinar como sera el reseteo de Password
 * @function
 * @name RestaurarPasswordUI
 * @property {Function} MakeComponent - Dibuja el componente con el que se restaurara el password
 * @return {Component} Componente de interfaz de usuario
 */
function RestaurarPasswordUI({ radioValue, radios, radioOnchange }) {
  /**
   * Renderiza el componente de interfaz de restauracion de password por usuario o Curp segun el useState radioValue
   * @function
   * @name RestaurarPasswordUI
   * @property {Function} MakeComponent - Dibuja el componente con el que se restaurara el password
   * @return {Component} Renderiza RestaurarPasswordUser o RestaurarPasswordCurp
   */
  function MakeComponent({ radioValue }) {
    if (radioValue === "1") {
      return <RestaurarPasswordUser />
    }
    return <RestaurarPasswordCurp />
  }

  return (
    <LayoutAutenticacion>
      <h1 className="fw-bold">Restaurar contraseña</h1>
      <p>
        Se le notificará vía correo electrónico el token de seguridad que debe
        proporcionar en el paso siguiente.
      </p>
      {/* <p>Selecciona como deseas restaurar tu contraseña</p>
                <div className="container_group_radio_check">
                    <div className="group_radio_check">
                        {radios.map((radio, idRadio) => (
                            <div key={idRadio}>
                                <input
                                    id={`radio-${idRadio}`}
                                    type="radio"
                                    name="radio"
                                    value={radio.value}
                                    checked={radioValue === radio.value}
                                    onChange={radioOnchange}
                                    className="input_radio_check"
                                />
                                <label htmlFor={`radio-${idRadio}`} className="fw-semibold label_radio_check">
                                    {radio.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div> */}
      <div className="mt-3">
        <MakeComponent radioValue={radioValue} />
      </div>
    </LayoutAutenticacion>
  )
}

export default RestaurarPasswordUI
