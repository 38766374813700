/**
 * Reducers de Autenticacion
 * @module ReducersAutenticacion
 * @author Jesus Gonzalez <jgp@nowaii.com>
 */
import { createSlice } from "@reduxjs/toolkit"
import { obtenerLocalStorage } from "../../utils/localStorage"

export const autenticacionSlice = createSlice({
  name: "autenticacion",
  initialState: {
    cargando: true,
    autenticado: false,
    token: obtenerLocalStorage("tokenUsuario") || "",
  },
  reducers: {
    /**
     * Carga de valores al iniciar sesion
     * @function
     * @name addSesion
     * @param {Object} action - Objecto que contiene el payload de los nuevos valores
     * @property {Boolean} action.payload.cargando - Nuevo valor para cargando
     * @property {Boolean} action.payload.autenticado - Nuevo valor autenticado
     * @property {String} action.payload.token - Token de la sesion del usuario
     * @return {Object} - Devuelve el state con los nuevos valores del payload
     */
    addSesion: (state, action) => ({
      ...state,
      cargando: action.payload.cargando,
      autenticado: action.payload.autenticado,
      token: action.payload.token,
    }),
    /**
     * Cambio de estado al verificar sesion / existencia del token en localstorage
     * @function
     * @name verificarSesion
     * @param {Object} action - Objecto que contiene el payload de los nuevos valores
     * @property {Boolean} action.payload.load - Nuevo valor para cargando
     * @property {Boolean} action.payload.autenticado - Nuevo valor autenticado
     * @returns {Object} - Devuelve el state con los nuevos valores del payload
     */
    verificarSesion: (state, action) => ({
      ...state,
      cargando: action.payload.load,
      autenticado: action.payload.autenticado,
    }),
    /**
     * Vaciado de estado al cerrar sesion
     * @function
     * @name closeSesion
     * @param {Object} action - Objecto que contiene el payload de los nuevos valores
     * @property {Boolean} action.payload.autenticado - Valor contrario al actual del state
     * @property {String} action.payload.token - Valor nuevo al token
     * @returns {Object} - Devuelve el state con los nuevos valores del payload
     */
    closeSesion: (state, action) => ({
      ...state,
      autenticado: action.payload.autenticado,
      token: action.payload.token,
    }),
    /**
     * Cambio de valor del cargando para algun evento
     * @function
     * @name cargandoEvento
     * @param {Object} action - Objecto que contiene el payload de los nuevos valores
     * @property {Boolean} action.payload.cargando - Valor contrario al actual
     * @returns {Object} - Devuelve el state con los nuevos valores del payload
     */
    cargandoEvento: (state, action) => ({
      ...state,
      cargando: action.payload.cargando,
    }),
  },
})

export const { addSesion, verificarSesion, closeSesion, cargandoEvento } =
  autenticacionSlice.actions
export default autenticacionSlice.reducer
