/**
 * Apertura y cierre de alertas
 * @module ControladorAlertas
 * @autor Jesus Gonzalez <jgp@nowaii.com>
 */
import { store } from "../app/Store"
import { addEvent, addMessage } from "../features/actions/alertsSlice"
/**
 * Apertura de alerta dentro de la aplicacion
 * @function
 * @name openModal
 * @param {String} message - Mensaje que aparecera dentro del alerta
 * @param {String} type - Tipo de alerta a mostrar (Sucess/Warning/Error)
 */
export const openModal = async (message, type) => {
  store.dispatch(addMessage({ message, type }))
  store.dispatch(addEvent({ show: true }))
}
/**
 * Cierre del componente de alerta
 * @function
 * @name closeModal
 */
export const closeModal = async () => {
  setTimeout(() => {
    store.dispatch(addEvent({ show: false }))
  }, 2500)
}

export const errorFetch = async () => {
  openModal("Servicio no disponible intente más tarde", "Error")
  closeModal()
}
