/**

Define el estado inicial del slice de restaurar contraseña.
@typedef {Object} InitialState
@property {string} token - Token de autenticación.
*/
/**

Slice de Redux para manejar el estado de restaurar contraseña.
@typedef {Object} RestaurarPasswordSlice
@property {string} name - Nombre del slice.
@property {InitialState} initialState - Estado inicial del slice.
@property {Object} reducers - Reducers que actualizan el estado del slice.
*/

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  token: "",
}

export const restaurarPassword = createSlice({
  name: "RestaurarPassword",
  initialState,
  reducers: {
    /**
     * Actualiza el token en el estado.
     * @param {InitialState} state - Estado actual del slice.
     * @param {Object} action - Objeto de acción con el token.
     * @param {string} action.payload.token - Token de autenticación.
     * @returns {InitialState} - Nuevo estado con el token actualizado.
     */
    addToken: (state, action) => ({
      ...state,
      token: action.payload.token,
    }),
  },
})

export const { addToken } = restaurarPassword.actions
export default restaurarPassword.reducer
