/**

Componente de entrada de un solo valor.
@param {boolean} focus Indica si el componente debe tener el enfoque.
@param {boolean} autoFocus Indica si el componente debe enfocarse automáticamente.
@param {object} props Propiedades del componente de entrada.
@returns Componente de entrada de un solo valor.
*/

import { useRef, memo, useLayoutEffect } from "react"
import usePrevious from "./hooks/usePrevious"

export function SingleInput({ focus, autoFocus, ...props }) {
  const inputRef = useRef(null)
  const prevFocus = usePrevious(!!focus)

  /**

  Efecto secundario que enfoca automáticamente el componente y selecciona su contenido si el componente recibe el enfoque.
  @param {boolean} autoFocus Indica si el componente debe enfocarse automáticamente.
  @param {boolean} focus Indica si el componente debe tener el enfoque.
  @param {boolean} prevFocus Indica si el componente tenía el enfoque anteriormente.
  */
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus()
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus()
        inputRef.current.select()
      }
    }
  }, [autoFocus, focus, prevFocus])

  return (
    <div className="content_input_code">
      <input ref={inputRef} {...props} />
    </div>
  )
}

/**

Componente de entrada de un solo valor optimizado para la actualización del componente padre.
*/
const SingleOTPInput = memo(SingleInput)
export default SingleOTPInput
