/**
 * Componente de los datos del modulo
 * @author Emmanuel Angeles <eam@nowaii.com>
 * @module Modulo
 */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**
 * Recibe como parametro las props para renderizar los datos de cada uno de los modulos
 * @function
 * @name Modulo
 * @property {String} iconPrefix - Valor que recibe el nombre del prefijo que utiliza el icono de fontAwesome
 * @property {String} iconName - Valor que recibe el nombre del icono
 * @property {String} name - Valor que recibe el nombre del modulo
 * @property {String} nameEnterprise - Valor que recibe el nombre de la empresa
 * @property {String} route -Valor que recibe el nombre de la ruta a la que se redirigira
 */
function Modulo({ iconPrefix, iconName, name, nameEnterprise, route, typeRoute = "modulo", mantenimiento = false }) {
  return (
    <a href={typeRoute === "modulo" ? `../${route}` : route} target={typeRoute === "modulo" ? "" : "blank"} className="link_general_card">
      <div className="general_card_blur h-100 d-flex flex-column justify-content-between">
        <div>
          <div className="d-flex justify-content-center">
            <div className="icon_card">
              <FontAwesomeIcon icon={[iconPrefix, iconName]} />
            </div>
          </div>
          <div className="mt-2">
            <div className="title_icon_card">
              <h2 className="mb-0">{name}</h2>
            </div>
          </div>
        </div>
        <div className="mt-1">
          <div className="title_icon_card">
            <h3 className="mb-0">
              {mantenimiento ? <><FontAwesomeIcon className="me-1" icon="fa-solid fa-toolbox" />{"Módulo en mantenimiento"}</> : <>{nameEnterprise}</>}
              </h3>
          </div>
        </div>
      </div>
    </a>
  )
}

export default Modulo
